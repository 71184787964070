import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '@app/types/types';

export default {
	apiKey: 'menu',
	path: ROUTES.BRANCHES_AVAILABILITY.path,
	label: 'branches_availability',
	iconName: 'calendar',
	menuOrder: 3,
	ownApp: true,
} as TPathOptions;
