import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const CampaignsPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.CAMPAIGNS.path,
			element: <Outlet />,
			children: [
				{
					index: true,
					element: <Navigate to={ROUTES.CAMPAIGNS.LIST.path} />,
				},
				{
					path: ROUTES.CAMPAIGNS.$.LIST.relativePath,
					element: <Outlet />,
					children: [
						{
							index: true,
							element: lazy(() => import('./Campaigns')),
						},
						{
							path: ROUTES.CAMPAIGNS.LIST.$.START_CAMPAIGN.relativePath,
							element: lazy(() => import('./AddEditCampaign')),
						},
						{
							path: ROUTES.CAMPAIGNS.LIST.$.EDIT_CAMPAIGN.relativePath,
							element: lazy(() => import('./AddEditCampaign')),
						},
						{
							path: ROUTES.CAMPAIGNS.LIST.$.PLANS.relativePath,
							element: lazy(() => import('./CampaignsPlans')),
						},
					],
				},
				{
					path: ROUTES.CAMPAIGNS.$.PAYMENT.relativePath,
					element: lazy(() => import('./CampaignsPayment')),
				},
				{
					path: ROUTES.CAMPAIGNS.$.SEGMENTS.relativePath,
					element: lazy(() => import('../Segments/Segments')),
				},
			],
		},
	],
};
