import { useDebugValue } from 'react';

import { generatedKeys } from '@app/data/queryKeysFactory';
import { useQuery } from '@app/QueryClient';
import { apiAnnouncements } from '../../Services/FreeOnboarding';
import { useTranslation } from 'react-i18next';

const { freeOnboarding } = generatedKeys;

export function useAnnouncements() {
	const { i18n } = useTranslation();
	const lang = i18n.language || i18n.resolvedLanguage;

	const q = useQuery({
		queryFn: () => apiAnnouncements(lang, 1),
		staleTime: Infinity,
		queryKey: freeOnboarding?.announcements?.({ lang }),
	});

	useDebugValue(q);

	return { ...q, data: q?.data?.data };
}
