import { EMerchantType } from '@app/store/authStore';
import { TPathOptions } from '@app/types/types';
import { ROUTES } from './routes';

export function generateCustomSectionedMenus(
	merchantType?: EMerchantType
): TPathOptions[] {
	if (merchantType === EMerchantType.Freemium) {
		return [
			{
				apiKey: 'freemium_overview',
				path: ROUTES.FREEMIUM_DASHBOARD.path,
				menuSection: 'business',
				label: 'loyalty_program',
				iconName: 'gift',
				menuOrder: 1,
				excludedFor: [],
				ownApp: false,
				disabled: true,
			},
			{
				apiKey: 'freemium_overview',
				path: ROUTES.FREEMIUM_DASHBOARD.path,
				menuSection: 'business',
				label: 'consumer_report',
				iconName: 'gift',
				menuOrder: 1,
				excludedFor: [],
				ownApp: false,
				disabled: true,
			},
			{
				apiKey: 'freemium_overview',
				path: ROUTES.FREEMIUM_DASHBOARD.path,
				menuSection: 'business',
				label: 'transactions',
				iconName: 'gift',
				menuOrder: 1,
				excludedFor: [],
				ownApp: false,
				disabled: true,
			},
			{
				apiKey: 'freemium_overview',
				path: ROUTES.FREEMIUM_DASHBOARD.path,
				menuSection: 'business',
				label: 'exports',
				iconName: 'gift',
				menuOrder: 1,
				excludedFor: [],
				ownApp: false,
				disabled: true,
			},
		];
	}

	return [];
}
