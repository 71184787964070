import React from 'react'
import DashboardLogoLayout from './DashboardLogoLayout'

const DefaultDashboardLogo = () => {
    return (
        <DashboardLogoLayout
            currentAppName='Zeal Dashboard'
        />
    )
}

export default DefaultDashboardLogo