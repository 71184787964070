import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const PremiumTransactionsPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.PREMIUM_TRANSACTIONS.path,
			element: <Outlet />,
			children: [
				{
					index: true,
					element: (
						<Navigate to={ROUTES.PREMIUM_TRANSACTIONS.TRANSACTIONS.path} />
					),
				},
				{
					path: ROUTES.PREMIUM_TRANSACTIONS.$.TRANSACTIONS.relativePath,
					element: lazy(() => import('./PremiumTransactions')),
				},
				{
					path: ROUTES.PREMIUM_TRANSACTIONS.$.DOWNLOADS.relativePath,
					element: lazy(() => import('./Downloads')),
				},
			],
		},
	],
};
