import React from 'react';
import { Flex, Label } from '@zeal/web-ui';
import { useTranslation } from 'react-i18next';

const sectionBadges = {
	business: (
		<Flex className="bg-general-surface-four px-2 py-1 rounded-lg">
			<Label.Thin200>👑</Label.Thin200>
		</Flex>
	),
};

export const MenuLinksSection = ({
	children,
	title,
	isCollapsed,
}: {
	title: string;
	children: React.ReactNode;
	isCollapsed?: boolean;
}) => {
	const { t } = useTranslation('sidedrawer');

	return (
		<>
			<Flex className="px-4 py-3" justify="between" align="center">
				<Label.Big100 variant="subdued" className="truncate block w-full">
					{!isCollapsed && title === 'business'
						? t(title).toUpperCase()
						: t('title')}
				</Label.Big100>
				{sectionBadges?.[title as keyof typeof sectionBadges]}
			</Flex>
			{children}
		</>
	);
};
