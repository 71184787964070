import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';

export const FeedbackPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.FEEDBACK.path,
			element: lazy(() => import('./Feedback')),
		},
	],
};
