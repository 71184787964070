import { CustomStepProps } from '@zeal/web-ui';

export const teamMembersSteps: (
	t: (key: string) => string
) => CustomStepProps[] = (t) => [
	{
		selector: '#team-members',
		title: t('team_members_title'),
		description: t('team_members_description'),
		arrowPosition: 'left',
		tourPlace: 'right',
		tourOverrideStyles: '!top-[-85px] !left-[15px]',
	},
	{
		selector: '#team-add-member',
		title: t('add_member_title'),
		description: t('add_member_description'),
		arrowPosition: 'right',
		tourPlace: 'left',
	},
	{
		selector: '#team-member-drawer',
		title: t('add_member_drawer_title'),
		description: t('add_member_drawer_description'),
		arrowPosition: 'right',
		tourPlace: 'left',
		tourOverrideStyles: '!top-[50px] !left-[-20px]',
	},
	{
		selector: '#team-member-table',
		title: t('success_adding_member_title'),
		description: t('success_adding_member_description'),
		arrowPosition: 'top-center',
		tourPlace: 'bottom',
	},
];
