import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const storiesPathOptions: TPathOptions = {
	apiKey: 'stories',
	exact: false,
	menuOrder: 4,
	ownApp: true,
	path: ROUTES.STORIES.path,
	label: 'stories',
	iconName: 'circleDashed',
	subMenu: [
		{
			path: ROUTES.STORIES.PAGE.path,
			label: 'stories',
		},
		{
			path: ROUTES.STORIES.ARCHIVED.path,
			label: 'archived',
		},
	],
};

export default storiesPathOptions;
