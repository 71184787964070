import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';
import { UnAuthCard } from '@app/UnAuth';

export const StoriesPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.STORIES.path,
			element: <Outlet />,
			children: [
				{
					index: true,
					element: <Navigate to={ROUTES.STORIES.PAGE.path} />,
				},
				{
					path: ROUTES.STORIES.$.PAGE.relativePath,
					element: lazy(() => import('./components/Stories')),
					aclProps: {
						aclGrants: ['view:stories'],
						unAuthComponent: UnAuthCard,
					},
				},
				{
					path: ROUTES.STORIES.$.REQUESTS.relativePath,
					element: lazy(() => import('./components/Requests')),
					aclProps: {
						aclGrants: ['view:stories'],
						unAuthComponent: UnAuthCard,
					},
				},
				{
					path: ROUTES.STORIES.$.ARCHIVED.relativePath,
					element: lazy(() => import('./components/Archived')),
					aclProps: {
						aclGrants: ['view:stories'],
						unAuthComponent: UnAuthCard,
					},
				},
			],
		},
	],
};
