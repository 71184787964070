import { sortBy } from 'lodash';
import { TPathOptions } from '@app/types/types';
import { appPathEntries } from '@app/AppRoutes/appPathEntries';

const appMenuEntries = {
	ownAppMenus: [] as TPathOptions[],
	mainAppMenus: [] as TPathOptions[],
	sectionedMenus: [] as TPathOptions[],
};

appPathEntries.forEach((entry) => {
	const menuCfg = {
		...entry,
		to: entry.path,
	};

	if (entry.menuOrder) {
		if (entry.ownApp) {
			appMenuEntries.ownAppMenus.push(menuCfg);
		} else if (entry.menuSection) {
			appMenuEntries.sectionedMenus.push(menuCfg);
		} else {
			appMenuEntries.mainAppMenus.push(menuCfg);
		}
	}
});

const sortMenu = (menuList: any) => sortBy(menuList, 'menuOrder');

const appMainMenus = sortMenu(appMenuEntries.mainAppMenus) as TPathOptions[];
const ownAppMenus = sortMenu(appMenuEntries.ownAppMenus) as TPathOptions[];
const sectionedMenus = sortMenu(appMenuEntries.sectionedMenus);

export const getAppRoutes = () => {
	return {
		appMainMenus,
		ownAppMenus,
		sectionedMenus,
	};
};
