import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions, RouteFlags } from '../App/types/types';

const transactionsPathOptions: TPathOptions = {
	apiKey: 'transactions',
	menuOrder: 9,
	ownApp: false,
	path: ROUTES.VISITS_MANAGEMENT.path,
	label: 'transactions',
	iconName: 'circleCheck',
	excludedFor: [],
	subMenu: [
		{
			path: ROUTES.VISITS_MANAGEMENT.TRANSACTIONS.path,
			label: 'transactions',
		},
		{
			path: ROUTES.VISITS_MANAGEMENT.RECEIPTS.path,
			label: 'receipts',
			excludedFor: [RouteFlags.SHOW_RECEIPTS_DISABLED],
		},
		// {
		// 	path: ROUTES.VISITS_MANAGEMENT.REDEMPTIONS.path,
		// 	label: 'redemptions',
		// 	excludedFor: [RouteFlags.SHOW_REDEMPTIONS_DISABLED],
		// },
	],
};

export default transactionsPathOptions;
