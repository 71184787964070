import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import * as Yup from 'yup';

export const VisitTransactionsRoutes = {
	VISITS_MANAGEMENT: route(
		'visits-management',
		{},
		{
			TRANSACTIONS: route('transactions', {}),
			RECEIPTS: route(
				'receipts',
				{}
				// PAGE: route('page/:id', {
				//   params: {
				//     id: yup(Yup.string()),
				//   },
				// }),
			),
			// REDEMPTIONS: route('redemptions', {}),
		}
	),
};
