import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Flex, Label, ZealSvg, Tooltip } from '@zeal/web-ui';
import {
	DashboardWrapper,
	ImageWrapper,
	VerticalDivider,
} from '../StyledComponents';

type DashboardLogoProps = {
	currentAppName: string;
	appLogo?: React.ReactNode;
	Tierlogo?: React.ReactNode;
};

export default function DashboardLogoLayout(props: DashboardLogoProps) {
	const { currentAppName, appLogo, Tierlogo } = props;

	const zealImage = useMemo(
		() => <ImageWrapper>{ZealSvg.zealIcon}</ImageWrapper>,
		[]
	);

	const isCutOff = currentAppName.length > 16;

	const displayedName = isCutOff
		? `${currentAppName.slice(0, 15).trim()}...`
		: currentAppName;

	const BusinessName = (
		<Label.Mid400 className="w-[145px] whitespace-nowrap text-ellipsis overflow-hidden">
			{displayedName}
		</Label.Mid400>
	);

	return (
		<DashboardWrapper align="center" justify="between" gap="8">
			<Flex align="center" gap="4">
				<Flex gap="3" align="center">
					<Link to="/">{appLogo ?? zealImage}</Link>
					{isCutOff ? (
						<Tooltip tooltipId="business-name-tooltip" title={currentAppName}>
							{BusinessName}
						</Tooltip>
					) : (
						BusinessName
					)}
				</Flex>
				{Tierlogo}
			</Flex>
			<VerticalDivider />
		</DashboardWrapper>
	);
}
