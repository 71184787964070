import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions, RouteFlags } from '../App/types/types';

const feedbackPathOptions: TPathOptions = {
	apiKey: 'feedback',
	path: ROUTES.FEEDBACK.path,
	label: 'feedback',
	iconName: 'star',
	menuOrder: 4,
	ownApp: false,
	excludedFor: [RouteFlags.NI_MERCHANT],
};

export default feedbackPathOptions;
