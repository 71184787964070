import { getCollectionName } from '@app/utils/translations';
import { ApiRequest } from '@zeal/zeal-lib';

export interface TranslationResponse {
	data: {
		attributes: {
			key: string;
			value: string;
		};
	}[];
	meta: {
		pagination: {
			page: number;
			pageCount: number;
		};
	};
}

const apiToken = process.env.REACT_APP_STRAPI_TOKEN;

const apiURL = process.env.REACT_APP_STRAPI_URL ?? '';

export const fetchTranslations = async (
	lang: string,
	moduleName: string,
	pageParam: number
) => {
	const collectionName = getCollectionName(moduleName);

	const translations = await new ApiRequest(`${apiURL}/api/${collectionName}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.addHeader('Authorization', `Bearer ${apiToken}`)
		.addQuery('locale', lang)
		.addQuery('pagination[pageSize]', '100')
		.addQuery('pagination[page]', pageParam?.toString())
		.GET<TranslationResponse>({
			noAuth: true,
		});

	const translationsObj = translations?.data?.reduce<Record<string, string>>(
		(acc, curr) => {
			const record = curr?.attributes;
			const key = record?.key;
			const value = record?.value;

			if (key && value) {
				acc[key] = value;
			}

			return acc;
		},
		{}
	);

	return {
		translationsObj: translationsObj as Record<string, string>,
		meta: translations.meta,
	};
};

export type Locals = {
	id: number;
	name: string;
	code: string;
	createdAt: string;
	updatedAt: string;
	isDefault: boolean;
};

export const fetchLocales = async () => {
	const locales = await new ApiRequest(`${apiURL}/api/i18n/locales`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.addHeader('Authorization', `Bearer ${apiToken}`)
		.GET<Locals[]>({
			noAuth: true,
		});

	return locales;
};
