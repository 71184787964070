import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions, RouteFlags } from '../App/types/types';

const recommendationsPathOptions: TPathOptions = {
	apiKey: 'recommendations',
	path: ROUTES.RECOMMENDATIONS.path,
	label: 'recommendations',
	iconName: 'thumbsUp',
	menuOrder: 7,
	ownApp: true,
};

export default recommendationsPathOptions;
