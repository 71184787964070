import React, { createContext, useContext, useState } from 'react';
import { TApiError } from '../../../QueryClient/types';
import {
	TMagicLinkFormContext,
	TMagicLinkForm,
	EMagicLinkStep,
} from '../../types';
import { EMagicLinkLSKeys, LSFormHandler } from './LSFormHandler';

export const MagicLinkFormContext = createContext<TMagicLinkFormContext>({
	step: EMagicLinkStep.EmailRegistration,
	goToNextStep: () => {},
	setStep: () => {},
	errors: {},
	setErrors: () => {},
	verificationEmail: '',
	setVerificationEmail: () => {},
});

export const useMagicLinkFormContext = () => useContext(MagicLinkFormContext);

export const MagicLinkFormProvider = ({
	children,
}: React.PropsWithChildren) => {
	const [errors, setErrors] = useState<TApiError<TMagicLinkForm>>({});
	const [step, setStep] = useState<EMagicLinkStep>(
		EMagicLinkStep.EmailRegistration
	);
	const [verificationEmail, setVerificationEmail] = useState('');

	const goToNextStep = () => {
		LSFormHandler.setStoredKey(EMagicLinkLSKeys.step, (step + 1).toString());
		setStep(step + 1);
	};

	const value: TMagicLinkFormContext = {
		step,
		errors,
		setErrors,
		goToNextStep,
		setStep,
		verificationEmail,
		setVerificationEmail,
	};

	return (
		<MagicLinkFormContext.Provider value={value}>
			{children}
		</MagicLinkFormContext.Provider>
	);
};
