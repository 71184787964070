import { ActivityLogRoutes } from '@modules/ActivityLog/routes';
import { AnalyticsRoutes } from '@modules/Analytics/routes';
import { AnnouncementsRoutes } from '@modules/Announcements/routes';
import { MagicLinkRoutes } from '@app/MagicLinkLogin/routes';
import { ArticlesRoutes } from '@modules/Articles/routes';
import { BaristasRoutes } from '@modules/Baristas/routes';
import { BranchesRoutes } from '@modules/Branches/routes';
import { BranchesAvailabilityRoutes } from '@modules/BranchesAvailability/routes';
import { ExportsRoutes } from '@modules/Exports/routes';
import { CardLinkSetupRoutes } from '@modules/CardLink/routes';
import { CardLinkActivityRoutes } from '@modules/CardLinkActivity/routes';
import { CardLinkDashboardRoutes } from '@modules/CardLinkDashboard/routes';
import { CampaignsRoutes } from '@modules/Campaigns/routes';
import { ConsumerReportRoutes } from '@modules/ConsumerReport/routes';
import { DashboardRoutes } from '@modules/Dashboard/routes';
import { FeedbackRoutes } from '@modules/Feedback/routes';
import { FreemiumDashboardRoutes } from '@modules/FreemiumDashboard/routes';
import { MenuRoutes } from '@modules/Menu/routes';
import { OrdersRoutes } from '@modules/Orders/routes';
import { OrderStatusHistoryRoutes } from '@modules/OrderStatusHistory/routes';
import { RecommendationsRoutes } from '@modules/Recommendations/routes';
import { CustomersRoutes } from '@modules/Records/routes';
import { RewardsRoutes } from '@modules/Rewards/routes';
import { SettingsRoutes } from '@modules/Settings/routes';
import { StoriesRoutes } from '@modules/Stories/routes';
import { TeamsRoutes } from '@modules/TeamMembers/routes';
import { TipsRoutes } from '@modules/Tip/routes';
import { TopUpRoutes } from '@modules/TopUp/routes';
import { SubscriptionsRoutes } from '@modules/Subscriptions/routes';
import { TechniciansSlotsRoutes } from '@modules/TechniciansSlots/routes';
import { TerminalManagementRoutes } from '@modules/TerminalManagement/routes';
import { VisitTransactionsRoutes } from '@modules/VisitTransactions/routes';
import { WheelOfFortuneRoutes } from '@modules/WheelOfFortune/routes';
import { FreemiumLoyaltyRoutes } from '@modules/FreemiumLoyalty/routes';
import { FreemiumTeamsRoutes } from '@modules/FreemiumTeams/routes';
import { GalleryRoutes } from '@modules/Gallery/routes';
import { GiftCardRoutes } from '@modules/GiftCard/routes';
import { GiftFriendRoutes } from '@modules/GiftFriend/routes';
import { PremiumDashboardRoutes } from '@modules/PremiumDashboard/routes';
import { PremiumTransactionsRoutes } from '@modules/PremiumTransactions/routes';
import { PromocodesRoutes } from '@modules/Promocodes/routes';
import { route } from 'react-router-typesafe-routes/dom';
import { FreeOnboardingRoutes } from '@modules/FreeOnboarding/routes';

const RootRoutes = {
	ROOT: route(''),
	LOGIN: route('login', {}),
	FORGOT_PASSWORD: route('forgot-password', {}),
};

export const ROUTES = {
	...RootRoutes,
	...ArticlesRoutes,
	...OrdersRoutes,
	...MenuRoutes,
	...RecommendationsRoutes,
	...RewardsRoutes,
	...CustomersRoutes,
	...BaristasRoutes,
	...BranchesRoutes,
	...BranchesAvailabilityRoutes,
	...ExportsRoutes,
	...StoriesRoutes,
	...ActivityLogRoutes,
	...SettingsRoutes,
	...OrderStatusHistoryRoutes,
	...TipsRoutes,
	...AnalyticsRoutes,
	...TopUpRoutes,
	...TeamsRoutes,
	...ArticlesRoutes,
	...TechniciansSlotsRoutes,
	...SubscriptionsRoutes,
	...VisitTransactionsRoutes,
	...CardLinkDashboardRoutes,
	...TerminalManagementRoutes,
	...CardLinkSetupRoutes,
	...CardLinkActivityRoutes,
	...WheelOfFortuneRoutes,
	...AnnouncementsRoutes,
	...TeamsRoutes,
	...FreemiumDashboardRoutes,
	...FreemiumLoyaltyRoutes,
	...PremiumDashboardRoutes,
	...FreemiumTeamsRoutes,
	...PremiumTransactionsRoutes,
	...CampaignsRoutes,
	...ConsumerReportRoutes,
	...DashboardRoutes,
	...FeedbackRoutes,
	...GiftCardRoutes,
	...GiftFriendRoutes,
	...GalleryRoutes,
	...PromocodesRoutes,
	...MagicLinkRoutes,
	...FreeOnboardingRoutes,
};
