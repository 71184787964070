import { ApiRequest } from '@zeal/zeal-lib';
import { useMutation } from '../QueryClient';

async function apiSaveToken(token: string) {
	return new ApiRequest('v3/admins/fcm-token')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify({ fcm_token: token }));
}

export default function useSaveToken(opt?: any) {
	const mutation = useMutation(apiSaveToken, {
		...opt,
		operation: 'add',
		disableToaster: true,
	});

	return {
		...mutation,
	};
}
