import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Outlet } from 'react-router-dom';
import MagicLinkLayout from './MagicLinkLayout';
import MagicLinkLoading from './components/MagicLinkLoading';

export const MagicLinkPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.MAGIC_LINK.path,
			element: (
				<MagicLinkLayout>
					<React.Suspense fallback={<MagicLinkLoading />}>
						<Outlet />
					</React.Suspense>
				</MagicLinkLayout>
			),
			children: [
				{
					index: true,
					element: lazy(() => import('../Components/NotFound/NotFound')),
				},
				{
					path: ROUTES.MAGIC_LINK.$.SIGN_UP.TOKEN.relativePath,
					element: lazy(() => import('./components/form/FormContent')),
				},
				{
					path: ROUTES.MAGIC_LINK.$.LOGIN.relativePath,
					element: lazy(() => import('./components/form/Login')),
				},
				{
					path: ROUTES.MAGIC_LINK.$.NOT_FOUND.relativePath,
					element: lazy(() => import('../Components/NotFound/NotFound')),
				},
			],
		},
	],
};
