import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import * as Yup from 'yup';

export const TopUpRoutes = {
	TOP_UP: route(
		'credits',
		{},
		{
			TOP_UP: route('top-up', {}),
			ADD_CARD: route('add-card', {}),
		}
	),
};
