import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const ordersStatusPathOptions: TPathOptions = {
	apiKey: 'admins:order_statuses',
	path: ROUTES.ORDER_STATUS_HISTORY.path,
	label: 'orders_status_history',
	iconName: 'history',
	menuOrder: 2,
	ownApp: true,
};

export default ordersStatusPathOptions;
