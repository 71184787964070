import { TPathOptions } from '@app/types/types';

const announcementsPathOptions: TPathOptions = {
	apiKey: 'announcements',
	path: '/announcements',
	label: 'announcements',
	iconName: 'scroll',
	menuOrder: 4,
	ownApp: true,
	excludedFor: [],
};

export default announcementsPathOptions;
