import React from 'react';

import { Flex, Grid } from '@zeal/web-ui';

import NavMenu from './components/NavMenu';
import { MagicLinkFormProvider } from './components/form/FormContext';
import { useFetchTranslation } from '@app/hooks';
import CarouselCard from './components/CarouselCard';

export default function MagicLinkLayout({
	children,
}: {
	children: React.ReactNode;
}) {
	useFetchTranslation('magicLink');

	return (
		<Grid cols={2} className="w-screen h-screen">
			<CarouselCard />
			<Flex isColumn>
				<NavMenu />
				<MagicLinkFormProvider>{children}</MagicLinkFormProvider>
			</Flex>
		</Grid>
	);
}
