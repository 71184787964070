import React from 'react';
import { Flex, InlineLoading } from '@zeal/web-ui';

export function AppContainerLoading() {
	return (
		<Flex
			justify="center"
			align="center"
			style={{
				height: '100vh',
			}}
		>
			<InlineLoading />
		</Flex>
	);
}
