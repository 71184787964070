import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const premiumTransactionsPathOptions: TPathOptions = {
	apiKey: 'premium_transactions',
	exact: false,
	menuOrder: 3,
	ownApp: false,
	path: ROUTES.PREMIUM_TRANSACTIONS.path,
	label: 'transactions',
	excludedFor: [],
	iconName: 'creditCard',
	subMenu: [
		{
			featureApiKey: 'premium_transactions:transactions_overview',
			label: 'transactions_overview',
			path: ROUTES.PREMIUM_TRANSACTIONS.TRANSACTIONS.path,
		},
		{
			featureApiKey: 'premium_transactions:transactions_downloads',
			label: 'downloads',
			path: ROUTES.PREMIUM_TRANSACTIONS.DOWNLOADS.path,
		},
	],
};

export default premiumTransactionsPathOptions;
