import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const MenuPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.MENU_MANAGEMENT.path,
			aclProps: {
				aclGrants: ['view:menu'],
			},
			element: <Outlet />,
			children: [
				{
					index: true,
					element: <Navigate to={ROUTES.MENU_MANAGEMENT.MENU.path} />,
				},
				{
					path: ROUTES.MENU_MANAGEMENT.$.MENU.relativePath,
					element: <Outlet />,
					children: [
						{
							index: true,
							element: lazy(() => import('./BranchesMenus')),
						},
						{
							path: ROUTES.MENU_MANAGEMENT.MENU.$.ADD_ITEM.relativePath,
							element: lazy(() => import('./AddMenuItem')),
						},
						{
							path: ROUTES.MENU_MANAGEMENT.MENU.$.EDIT_ITEM.relativePath,
							element: lazy(() => import('./EditMenuItem')),
						},
						{
							path: ROUTES.MENU_MANAGEMENT.MENU.$.SHOW_MENU.relativePath,
							element: lazy(() => import('./Menu')),
						},
					],
				},
			],
		},
	],
};
