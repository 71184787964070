import React, { useMemo } from 'react';
import DashboardLogoLayout from './DashboardLogoLayout';
import { Image } from '@zeal/web-ui';
import { useLoggedIn } from '@app/ACL/useLogin';
import { useAppState } from '@app/store/appStore';
import { ImageWrapper } from '../StyledComponents';
import { useBusinessInfo } from '@app/BusinessAccount/useBusinessInfo';
import NavbarInitials from './NavbarInitials';
import FreeLogo from './logos/FreeLogo';
import CardlinkLogo from './logos/CardlinkLogo';
import BusinessLogo from './logos/BusinessLogo';
import BusinessPlusLogo from './logos/BusinessPlusLogo';

const BusinessDashboardLogo = () => {
	const { merchantType } = useLoggedIn();
	const { customAppName } = useAppState();
	const { data } = useBusinessInfo();
	const photoLink = data?.business?.photo;

	const customAppImage = useMemo(
		() => (
			<ImageWrapper>
				<Image
					rounded="lg"
					width={48}
					height={48}
					src={photoLink}
					className="max-w-max"
				/>
			</ImageWrapper>
		),
		[photoLink]
	);

	const appLogo =
		photoLink != null ? (
			customAppImage
		) : (
			<NavbarInitials username={customAppName} />
		);

	const tierImage = useMemo(() => {
		switch (merchantType) {
			case 'freemium':
				return <FreeLogo />;
			case 'cardlink':
				return <CardlinkLogo />;
			case 'premium':
				return <BusinessLogo />;
			// case 'business_plus': Not needed yet but kept it for future use
			// 	return <BusinessPlusLogo />;
			default:
				return <></>;
		}
	}, [merchantType]);

	return (
		<DashboardLogoLayout
			Tierlogo={tierImage}
			appLogo={appLogo}
			currentAppName={customAppName ?? 'Zeal'}
		/>
	);
};

export default BusinessDashboardLogo;
