import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const TerminalManagementPathEntry: IModuleOptions = {
  ...pathOpt,
  routes: [
    {
      path: ROUTES.TERMINAL_MANAGEMENT.path,
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <Navigate to={ROUTES.TERMINAL_MANAGEMENT.BRANCHES.path} replace />,
        },
        {
          path: ROUTES.TERMINAL_MANAGEMENT.$.BRANCHES.relativePath,
          element: lazy(() => import('./BranchesTerminals')),
          aclProps: {
            aclGrants: [],
          },
        },
        {
          path: ROUTES.TERMINAL_MANAGEMENT.$.TERMINALS.relativePath,
          element: lazy(() => import('./components/branchTerminals')),
          aclProps: {
            aclGrants: [],
          },
        },
      ],
    },
  ],
};

