import { Flex, Label } from '@zeal/web-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function FreeLogo() {
	const { t } = useTranslation('common');
	return (
		<Flex
			align="center"
			justify="center"
			className="py-1 px-3 rounded-[4px] h-8 bg-[#F3F4F5]"
			style={{
				minWidth: '100px',
			}}
		>
			<Label.Mid300 variant="black">{t('free')}</Label.Mid300>
		</Flex>
	);
}
