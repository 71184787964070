import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import * as Yup from 'yup';

export const TipsRoutes = {
	TIPS: route(
		'tips',
		{},
		{
			HISTORY: route('history', {}),
			DISTRIBUTION: route('distribution', {}),
		}
	),
};
