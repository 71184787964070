import React from "react";
import { Container } from "../Login/Login";
import EmailSentCard from "./components/EmailSentCard";
import ForgetPasswordCard from "./components/ForgetPasswordCard";
import { useFetchTranslation } from "@app/hooks";
import { ParentShimmer } from "@zeal/web-ui";
export default function ForgotPassword() {
  const [emailSent, setEmailSent] = React.useState(false);
  const { isLoading } = useFetchTranslation("login");
  const shownCard = emailSent ? /* @__PURE__ */ React.createElement(EmailSentCard, null) : /* @__PURE__ */ React.createElement(ForgetPasswordCard, { setEmailSent });
  return /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(ParentShimmer, { isLoading }, shownCard));
}
