import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const terminalManagementPathOptions: TPathOptions = {
	apiKey: 'terminal_management',
	path: ROUTES.TERMINAL_MANAGEMENT.path,
	label: 'terminal_management',
	iconName: 'mobileRetro',
	menuOrder: 3,
	ownApp: false,
	subMenu: [
		{
			featureApiKey: 'terminal_management:branches_and_terminals',
			label: 'business',
			path: ROUTES.TERMINAL_MANAGEMENT.BRANCHES.path,
		},
    {
      featureApiKey: 'terminal_management:branches_and_terminals',
      label: 'branches_and_terminals',
      path: ROUTES.TERMINAL_MANAGEMENT.TERMINALS.path,
    }
	],
	excludedFor: [],
};

export default terminalManagementPathOptions;
