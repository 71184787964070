import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import * as Yup from 'yup';

export const MenuRoutes = {
	MENU_MANAGEMENT: route(
		'menu-management',
		{},
		{
			MENU: route(
				'menu',
				{
					searchParams: {
						categoryId: yup(Yup.string()),
						subcategoryId: yup(Yup.string()),
						branchId: yup(Yup.string().required()),
					},
				},
				{
					ADD_ITEM: route('items/add', {
						searchParams: {
							menuId: yup(Yup.string().required()),
						},
					}),
					EDIT_ITEM: route('items/edit', {
						searchParams: {
							itemId: yup(Yup.string().required()),
							menuId: yup(Yup.string().required()),
						},
					}),
					SHOW_MENU: route('menus/:menuId', {
						params: {
							menuId: yup(Yup.string().required()),
						},
					}),
				}
			),
		}
	),
};
