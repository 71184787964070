import React from 'react';
import { useTranslation } from 'react-i18next';

import { Label, Icon, Card, Flex } from '@zeal/web-ui';

export default function UnAuth() {
	return <UnAuthCard />;
}

export function UnAuthCard() {
	const { t } = useTranslation('common');

	return (
		<Card fillHeight m="md" p="sm">
			<Flex isColumn justify="center" align="center" className="h-full">
				<Icon
					iconName="lockKeyhole"
					variant="regular"
					size="3x"
					className="m-4"
				/>
				<Label size="2xl" weight="semiBold" className="my-2">
					{t('access_restricted')}
				</Label>
				<Label variant="lightGray" size="sm" className="my-2">
					{t('access_msg')}
				</Label>
			</Flex>
		</Card>
	);
}
