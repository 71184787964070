import { useEffect, useRef } from 'react';
import { PageConfig, usePageStore } from '@app/store/pageStore';

export function usePageConfig({ title }: PageConfig) {
	const { setPageConfig } = usePageStore();
	const previousTitleRef = useRef<React.ReactNode>(null);

	useEffect(() => {
		setPageConfig({
			title,
		});
		previousTitleRef.current = title;
	}, [title, setPageConfig]);

	useEffect(() => {
		setPageConfig({
			title,
		});

		return () => {
			setPageConfig({
				title: null,
			});
		};
	}, []);
}
