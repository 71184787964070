import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Flex, Image, Label } from '@zeal/web-ui';

import NotFoundImage from '@modules/assets/404.svg';
import { ROUTES } from '@app/AppRoutes/routes';

export default function NotFound() {
	const { t } = useTranslation('common');

	const navigate = useNavigate();

	const goToHomePage = useCallback(() => {
		navigate(ROUTES.ROOT.path);
	}, []);

	return (
		<Flex
			isColumn
			gap="xl"
			justify="center"
			align="center"
			className="max-w-xl mx-auto text-center min-h-screen py-4"
		>
			<Flex isColumn gap="base" justify="center" align="center">
				<Label variant="black" weight="bold" size="5xl">
					{t('page_not_found')}
				</Label>
				<Label variant="black" size="lg">
					{t('not_found_msg')}
				</Label>
				<Button variant="primary" onClick={goToHomePage}>
					{t('go_home')}
				</Button>
			</Flex>
			<Image src={NotFoundImage} />
		</Flex>
	);
}
