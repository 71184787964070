import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import * as Yup from 'yup';

export const RewardsRoutes = {
	REWARDS: route(
		'rewards',
		{},
		{
			POINTS_AND_REWARDS: route('points-and-rewards'),
		}
	),
};
