import './i18n';
import './init';
import './styles.css';

import '@zeal/web-ui/dist/index.css';
import React from 'react';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';

import { BrowserRouter } from 'react-router-dom';
import { QueryContextProvider } from '@zeal/web-ui';

import AppRoutes from './App/AppRoutes/AppRoutes';
import { QueryClientProvider } from './App/QueryClient';
import { QueryStateProvider } from './App/useQueryString';
import { OwnAppProvider } from './App/AppContainer/useOwnApp';
import { createRoot } from 'react-dom/client';
import { Toaster } from 'react-hot-toast';

createRoot(document.getElementById('root')).render(
	<SentryErrorBoundary fallback="An error has occurred, please refresh">
		<React.StrictMode>
			<BrowserRouter>
				<QueryClientProvider>
					<QueryContextProvider>
						<QueryStateProvider>
							<Toaster />
							<OwnAppProvider>
								<AppRoutes />
							</OwnAppProvider>
						</QueryStateProvider>
					</QueryContextProvider>
				</QueryClientProvider>
			</BrowserRouter>
		</React.StrictMode>
	</SentryErrorBoundary>,
	document.getElementById('root')
);
