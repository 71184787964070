import React, { useCallback, useMemo, useState } from 'react';
import { Button, DropdownMenu, Flex, Icon, Image, Label } from '@zeal/web-ui';
import { useTranslation } from 'react-i18next';
import { useFetchLocals } from '@app/hooks';
import LanguageButton from '@app/AppContainer/components/LanguageButton';
import { StyledFreemiumLanguageTrigger } from '@app/AppContainer/components/StyledComponents';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@app/AppRoutes/routes';

export const APP_WEBSITE = process.env.REACT_APP_WEBSITE ?? '';

export default function NavMenu() {
	const { t } = useTranslation('magicLink');

	const [isClosed, setIsClosed] = useState(true);
	const handleOpenChange = useCallback(
		(isClosed: boolean) => setIsClosed(!isClosed),
		[]
	);

	const { i18n } = useTranslation();
	const navigate = useNavigate();
	const { data } = useFetchLocals();

	const languageOptions = useMemo(() => data || [], [data]);

	const generateLanguageButtons = languageOptions.map((language) => (
		<LanguageButton
			key={language.id}
			value={language.code}
			label={language.name}
			onClick={() => {
				setIsClosed(true);
			}}
			flag={
				<Image
					width={24}
					height={24}
					src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${
						language.code.split('-')[1]
					}.svg`}
				/>
			}
		/>
	));

	return (
		<Flex justify="end" align="center" gap="8" className="py-4 px-8">
			<DropdownMenu onOpenChange={handleOpenChange} open={!isClosed}>
				<DropdownMenu.Trigger className="group">
					<StyledFreemiumLanguageTrigger
						align="center"
						className="px-2 py-3"
						gap='2'
						isClosed={isClosed}
					>
						<Image
							width={24}
							height={24}
							src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${
								i18n.language.split('-')[1]
							}.svg`}
						/>
						<Label.Mid300>
							{
								languageOptions.find(
									(language) => language.code === i18n.language
								)?.name
							}
						</Label.Mid300>
						<Icon
							iconName="chevronDown"
							variant="regular"
							colorVariant="darkGrey"
						/>
					</StyledFreemiumLanguageTrigger>
				</DropdownMenu.Trigger>
				<DropdownMenu.Content style={{ width: '180px', padding: '8px' }}>
					<Flex isColumn gap="sm">
						{generateLanguageButtons}
					</Flex>
				</DropdownMenu.Content>
			</DropdownMenu>
			<Button
				variant="ghost"
				size="md"
				onClick={() => navigate(ROUTES.LOGIN.path)}
			>
				{t('login')}
			</Button>
		</Flex>
	);
}
