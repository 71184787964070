import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const GiftCardPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.GIFT_CARD.path,
			element: <Outlet />,
			children: [
				{
					index: true,
					element: <Navigate to={ROUTES.GIFT_CARD.LIST.path} />,
				},
				{
					path: ROUTES.GIFT_CARD.$.LIST.relativePath,
					element: lazy(() => import('./GiftCards')),
				},
				{
					path: ROUTES.GIFT_CARD.$.REPORTS.relativePath,
					element: lazy(() => import('./GiftCardsReport')),
				},
			],
		},
	],
};
