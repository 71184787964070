import { ApiRequest } from '@zeal/zeal-lib';
import type { BusinessInfoAPI } from '@app/BusinessAccount/useBusinessInfo';
import type { TPaymentCard, TStoreCard, TTopUp } from '@modules/TopUp/types';
import { ENDPOINTS } from '../endpoints';

export async function getPaymentKey() {
	return new ApiRequest(ENDPOINTS.topUp.paymentKey)
		.addHeader('Accept', 'application/json')
		.POST<
			ApiResponse<{
				token: string;
			}>
		>('{}')
		.then((res) => res?.data?.token);
}

export async function getCredit() {
	return new ApiRequest(ENDPOINTS.business.info)
		.addHeader('Accept', 'application/json')
		.GET<ApiResponse<BusinessInfoAPI>>()
		.then((res) => {
			const data = res?.data || {};
			return data?.credit;
		});
}

export async function listTopUpPlans() {
	return new ApiRequest(ENDPOINTS.topUp.listPlans)
		.addHeader('Accept', 'application/json')
		.GET<ApiResponse<TTopUp[]>>()
		.then((payload) => payload?.data);
}

export async function listPaymentCards() {
	return new ApiRequest(ENDPOINTS.topUp.listCards)
		.addHeader('Accept', 'application/json')
		.GET<ApiResponse<TPaymentCard[]>>()
		.then((payload) => payload?.data?.filter((card) => !!card?.masked_card));
}

export async function setDefaultPaymentCard(id: string) {
	return new ApiRequest(ENDPOINTS.topUp.setDefaultCard(id))
		.addHeader('Accept', 'application/json')
		.POST(JSON.stringify('{}'));
}

export async function deletePaymentCard(id: string) {
	return new ApiRequest(ENDPOINTS.topUp.deleteCard(id))
		.addHeader('Accept', 'application/json')
		.POST('');
}

export async function storePaymentCard(data: TStoreCard) {
	return new ApiRequest(ENDPOINTS.topUp.storeCard)
		.addHeader('Accept', 'application/json')
		.POST(JSON.stringify(data));
}

export async function selectPlan(planId: string) {
	return new ApiRequest(ENDPOINTS.topUp.selectPlan)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST<any>(JSON.stringify({ plan_uuid: planId }))
		.then((res) => res.data.data);
}
