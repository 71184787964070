import React from 'react';
import NavBarAvatarLayout from './NavBarAvatarLayout';
import { useAvatarInfo } from './useAvatarInfo';

const BusinessNavBarAvatar = () => {
	const { displayAccessLevel, displayName } = useAvatarInfo();

	return (
		<NavBarAvatarLayout
			displayAccessLevel={displayAccessLevel}
			displayName={displayName}
			showCredits={false}
		/>
	);
};

export default BusinessNavBarAvatar;
