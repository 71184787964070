import { useQuery } from '@app/QueryClient';
import { apiFetchAnnouncementFeature } from '@app/Services/Dashboard';
import { generatedKeys } from '../queryKeysFactory';

const { announcements } = generatedKeys;

export function useFeatureAnnouncement() {
	const q = useQuery({
		queryKey: announcements?.all?.({}),
		queryFn: apiFetchAnnouncementFeature,
		staleTime: Infinity,
	});

	return q;
}
