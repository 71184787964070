import { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';

export const activityLogPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.ACTIVITY_LOG.path,
			element: lazy(() => import('./ActivityLog')),
			aclProps: {
				aclGrants: ['superAdmin'],
			},
		},
	],
};
