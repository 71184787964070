import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18next
	// i18next-http-backend
	// loads translations from 'public/locales'
	// https://github.com/i18next/i18next-http-backend
	.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		detection: {
			convertDetectedLanguage: (lng) => {
				if (lng === 'en-US' || !lng) {
					return 'en-GB';
				}
				return lng;
			},
			order: ['localStorage', 'navigator'],
			caches: ['localStorage'],
		},
		debug: false,
		resources: {},
		partialBundledLanguages: true,
		load: 'all',
		ns: [
			'common',
			'visits',
			'teams',
			'translation',
			'analytics',
			'segments',
			'orders',
			'activityLog',
		],
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		defaultNS: 'common',
		react: {
			useSuspense: false,
		},
	});

export default i18next;
