import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const WheelOfFortunePathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.WHEEL_OF_FORTUNE.path,
			element: <Outlet />,
			aclProps: {
				aclGrants: ['view:wheelOfFortune'],
			},
			children: [
				{
					index: true,
					element: (
						<Navigate
							to={ROUTES.WHEEL_OF_FORTUNE.$.WHEELS.relativePath}
							replace
						/>
					),
				},
				{
					path: ROUTES.WHEEL_OF_FORTUNE.$.WHEELS.relativePath,
					element: <Outlet />,
					children: [
						{
							element: lazy(() => import('./WheelOfFortune')),
							index: true,
						},
						{
							path: ROUTES.WHEEL_OF_FORTUNE.WHEELS.$.REDEMPTION_DETAILS
								.relativePath,
							element: lazy(() => import('./RedemptionsDetails')),
						},
					],
				},
				{
					path: ROUTES.WHEEL_OF_FORTUNE.$.ARCHIVE.relativePath,
					element: <Outlet />,
					children: [
						{
							element: lazy(() => import('./ArchivedWheelOfFortune')),
							index: true,
						},
						{
							path: ROUTES.WHEEL_OF_FORTUNE.ARCHIVE.$.REDEMPTION_DETAILS
								.relativePath,
							element: lazy(() => import('./RedemptionsDetails')),
						},
					],
				},
				{
					path: ROUTES.WHEEL_OF_FORTUNE.$.REPORT.relativePath,
					element: lazy(() => import('./WheelOfFortuneReport')),
				},
			],
		},
	],
};
