import React from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery, useMutation, queryClient } from '@app/QueryClient';
import { generatedKeys } from '@app/data';
import { MutationOptions } from '@app/QueryClient/types';
import type { TPaymentCard, TStoreCard, TTopUp } from '@modules/TopUp/types';
import {
	getCredit,
	listPaymentCards,
	getPaymentKey,
	selectPlan,
	setDefaultPaymentCard,
	storePaymentCard,
	listTopUpPlans,
	deletePaymentCard,
} from '@services/TopUp';

const { topUp } = generatedKeys;

export function useCredit() {
	const q = useQuery({
		queryFn: getCredit,
		staleTime: Infinity,
		queryKey: topUp.credit({}),
	});

	React.useDebugValue(q);

	return q;
}

export function useTopUpPlans() {
	const q = useQuery({
		queryFn: listTopUpPlans,
		staleTime: Infinity,
		queryKey: topUp.plans({}),
	});

	React.useDebugValue(q);
	return { ...q, data: q.data as TTopUp[] | undefined };
}

export function usePaymentCards() {
	const q = useQuery({
		queryFn: listPaymentCards,
		staleTime: Infinity,
		queryKey: topUp.paymentCards({}),
	});

	React.useDebugValue(q);
	return { ...q, data: q.data as TPaymentCard[] | undefined };
}

export function usePaymentKey() {
	const q = useQuery({
		queryFn: getPaymentKey,
		staleTime: Infinity,
		queryKey: topUp.paymentKey({}),
		refetchOnMount: 'always',
	});

	React.useDebugValue(q);
	return { ...q, data: q.data as string | undefined };
}

export function useSetDefaultPaymentCard(
	opt?: MutationOptions<any, any, string, any>
) {
	const { t } = useTranslation('topUp');

	const q = useMutation(setDefaultPaymentCard, {
		...opt,
		moduleName: t('TopUp'),
		operation: 'add',
		successMessage: t('Default payment card updated'),
		failMessage: t('Failed to update default payment card'),
	});

	React.useDebugValue(q);
	return { ...q, doSetDefaultPaymentCard: q.mutateAsync };
}

export function useDeletePaymentCard(
	opt?: MutationOptions<any, any, string, any>
) {
	const { t } = useTranslation('topUp');

	const q = useMutation(deletePaymentCard, {
		...opt,
		moduleName: t('Card'),
		operation: 'delete',
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: topUp.paymentCards({}),
			});
		},
	});

	React.useDebugValue(q);
	return { ...q, doDeletePaymentCard: q.mutateAsync };
}

export function useStorePaymentCard(
	opt?: MutationOptions<any, any, TStoreCard, any>
) {
	const { t } = useTranslation('topUp');

	const q = useMutation(storePaymentCard, {
		...opt,
		moduleName: t('Card'),
		operation: 'add',
	});

	React.useDebugValue(q);
	return { ...q, doStorePaymentCard: q.mutateAsync };
}

export function useSelectPlan(opt?: MutationOptions<any, any, string, any>) {
	const { t } = useTranslation('topUp');

	const q = useMutation(selectPlan, {
		...opt,
		moduleName: t('TopUp'),
		operation: 'add',
		failMessage: t('payment_failed_message'),
	});

	React.useDebugValue(q);
	return { ...q, doSelectPlan: q.mutateAsync };
}
