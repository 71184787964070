import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const promocodesPathOptions: TPathOptions = {
	apiKey: 'promo_code',
	path: ROUTES.PROMOCODES.path,
	label: 'promo_codes',
	iconName: 'badgePercent',
	menuOrder: 8,
	ownApp: false,
	excludedFor: [],
	subMenu: [
		{
			label: 'promocodes',
			path: ROUTES.PROMOCODES.LIST.path,
		},
		{
			label: 'promocodes_report',
			path: ROUTES.PROMOCODES.REPORT.path,
		},
	],
};

export default promocodesPathOptions;
