import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const subscriptionsPathOptions: TPathOptions = {
	apiKey: 'subscriptions',
	path: ROUTES.SUBSCRIPTIONS.path,
	label: 'subscriptions',
	iconName: 'userGroup',
	menuOrder: 7,
	ownApp: true,
};

export default subscriptionsPathOptions;
