import { route } from 'react-router-typesafe-routes/dom';

export const PromocodesRoutes = {
	PROMOCODES: route(
		'promocodes',
		{},
		{
			LIST: route('list', {}),
			REPORT: route('report', {}),
		}
	),
};
