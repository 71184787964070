import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const GiftFriendPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.GIFT_FRIEND.path,
			element: <Outlet />,
			children: [
				{
					index: true,
					element: <Navigate to={ROUTES.GIFT_FRIEND.GIFTS.path} />,
				},
				{
					path: ROUTES.GIFT_FRIEND.$.GIFTS.relativePath,
					element: lazy(() => import('./components/Gifts')),
				},
				{
					path: ROUTES.GIFT_FRIEND.$.CATEGORIES.relativePath,
					element: lazy(() => import('./components/Categories')),
				},
				{
					path: ROUTES.GIFT_FRIEND.$.DISCOUNTS.relativePath,
					element: lazy(() => import('./components/Discounts')),
				},
			],
		},
	],
};
