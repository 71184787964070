import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions, RouteFlags } from '../App/types/types';

const articlePathOptions: TPathOptions = {
	apiKey: 'articles',
	path: ROUTES.ARTICLES.path,
	label: 'articles',
	iconName: 'newspaper',
	menuOrder: 8,
	ownApp: true,
};

export default articlePathOptions;
