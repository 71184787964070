import { Button, Card, Flex } from '@zeal/web-ui';
import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const Content = styled.div`
	grid-area: appContent;
	max-height: calc(100vh - var(--main-headerbar-height));
	overflow-y: auto;
	margin: 0 !important;
	padding: 0 !important;
`;

export const TextCenter = styled.div`
	width: 100%;
	display: grid;
	text-align: center;
	place-items: center;
	place-content: center;
`;

export const FormCard: typeof Card = styled(Card)`
	min-width: 500px;
`;

export const StyledForm = styled(Flex)`
	margin-top: 75px !important;
`;

export const StyledSignUpLine = styled(Button)`
	margin-top: 24px;
	background: transparent;
	color: #000000;
	width: 100%;
	display: grid;
	text-align: center;
	place-items: center;
	place-content: center;
`;

export const StyledSlider = styled(Swiper)`
	width: 100%;
	height: 100%;
	.swiper-slide {
		display: flex;
		flex-direction: column;
		height: 100%;
		align-items: center;
		justify-content: space-between;
		padding-top: 70px;
	}
`;
