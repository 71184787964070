import { route } from 'react-router-typesafe-routes/dom';

export const FreemiumLoyaltyRoutes = {
	FREEMIUM_LOYALTY: route(
		'loyalty',
		{},
		{
			SETTINGS: route('settings', {}),
			REPORTS: route('reports', {}),
			DOWNLOADS: route('downloads', {}),
		}
	),
};
