import ms from "ms";
import { addUnit, formatDate, isValidDate } from "@zeal/zeal-lib";
export const defaultDateRange = {
  startDate: addUnit(/* @__PURE__ */ new Date(), "month", -1),
  endDate: /* @__PURE__ */ new Date()
};
export function formatToApiDate(date) {
  return formatDate(date, "YYYY-MM-DD HH:mm");
}
export function sanitizeDateRange({ dateFrom, dateTo }) {
  const from = isValidDate(new Date(dateFrom)) ? new Date(dateFrom) : defaultDateRange.startDate;
  const to = isValidDate(new Date(dateTo)) ? new Date(dateTo) : defaultDateRange.endDate;
  from.setHours(0, 0, 0, 0);
  to.setHours(0, 0, 0, 0);
  if (from > to) {
    return {
      dateFrom: defaultDateRange.startDate,
      dateTo: defaultDateRange.endDate
    };
  } else {
    return {
      dateFrom: from,
      dateTo: to
    };
  }
}
export const getLastRange = ({ dateFrom, dateTo }) => {
  const { dateFrom: from, dateTo: to } = sanitizeDateRange({
    dateFrom,
    dateTo
  });
  const diff = to - from;
  const lastFrom = formatToApiDate(new Date(from - diff));
  const lastTo = formatToApiDate(new Date(to - diff));
  return { lastFrom, lastTo };
};
export const calculateRangeType = ({ dateFrom, dateTo }) => {
  const { dateFrom: from, dateTo: to } = sanitizeDateRange({
    dateFrom,
    dateTo
  });
  const dateFromInMilliseconds = new Date(from).getTime();
  const dateToInMilliseconds = new Date(to).getTime();
  const oneDayInMilliseconds = ms("1d");
  const rangeType = dateToInMilliseconds - dateFromInMilliseconds;
  if (rangeType === oneDayInMilliseconds) {
    return "hours";
  } else {
    return "days";
  }
};
