import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const ExportsPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.EXPORTS.path,
			aclProps: {
				aclGrants: ['view:reports'],
			},
			element: <Outlet />,
			children: [
				{
					index: true,
					element: <Navigate to={ROUTES.EXPORTS.VISITS.path} />,
				},
				{
					path: ROUTES.EXPORTS.VISITS.path,
					element: lazy(() => import('./VisitsExports')),
					featureApiKey: 'export:visits',
				},
				{
					path: ROUTES.EXPORTS.CUSTOMERS.path,
					element: lazy(() => import('./CustomersExports')),
					featureApiKey: 'export:customers',
				},
				{
					path: ROUTES.EXPORTS.PROMO_CODES.path,
					element: lazy(() => import('./PromocodesExport')),
					featureApiKey: 'export:promo_code',
				},
				{
					path: ROUTES.EXPORTS.WHEEL_OF_FORTUNE.path,
					element: lazy(() => import('./WheelOfFortune')),
					featureApiKey: 'export:wheel_of_fortune',
				},
			],
		},
	],
};
