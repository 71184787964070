import {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from 'react';
import { useIsFeatureVisible } from '../../FeatureControl/FeatureControl';
import { useLoggedIn } from '../../ACL/useLogin';
import { useBusinessProperties } from '../../BusinessAccount/useBusinessInfo';
import { useGiftFriendRequestStatus } from '@data/GiftFriend';
import { filterPaths } from '../../Components/FilteredRoute';
import { TPathOptions, RouteFlags, SubMenuOptions } from '../../types/types';
import { useFeatureAnnouncement } from '@app/data/Dashboard';
import { FeatureKey } from '@app/FeatureControl/types';
import { MenuLinksProps } from './MenuLinks';
import { generateCustomSectionedMenus } from '@app/AppRoutes/generateCustomPathEntries';
import { EMerchantType } from '@app/store/authStore';

export const useFilteredMenuLinks = ({
	ownAppMenus,
	appMainMenus,
	sectionedMenus: originalSectionedMenus,
}: Pick<MenuLinksProps, 'appMainMenus' | 'ownAppMenus' | 'sectionedMenus'>) => {
	const { isFeatureVisible } = useIsFeatureVisible();
	const { data: businessProperties } = useBusinessProperties();
	const { data: featuredRoutes } = useFeatureAnnouncement();
	const { isNIMerchant, merchantType } = useLoggedIn();
	const { data: giftFriendStatusData } = useGiftFriendRequestStatus();
	const isGiftFriendEnabled = giftFriendStatusData?.data?.is_enabled;
	const [appliedExcludingFlags, setAppliedExcludingFlags] = useState<
		RouteFlags[]
	>([]);

	const sectionedMenus = useMemo(() => {
		return [
			...originalSectionedMenus,
			...generateCustomSectionedMenus(merchantType as EMerchantType),
		];
	}, [originalSectionedMenus, merchantType]);

	useLayoutEffect(() => {
		const flags: RouteFlags[] = [];

		flags.push(
			RouteFlags.SHOW_RECEIPTS_DISABLED,
			RouteFlags.SHOW_REDEMPTIONS_DISABLED
		);

		if (isNIMerchant) {
			flags.push(RouteFlags.NI_MERCHANT);
		}

		if (!isGiftFriendEnabled) {
			flags.push(RouteFlags.GIFT_FRIEND_DISABLED);
		}
		setAppliedExcludingFlags(flags);
	}, [isNIMerchant, isGiftFriendEnabled]);

	const featuredApiKeys = useMemo(() => {
		if (!featuredRoutes) {
			return new Set<FeatureKey>();
		}

		return new Set<FeatureKey>(
			featuredRoutes?.data
				?.filter((item: any) => item.attributes.isMenuHighlighted)
				?.map((item: any) => item.attributes.feature_key)
		);
	}, [featuredRoutes]);

	const isRouteFeatured = useCallback(
		(apiKey?: FeatureKey) => !!apiKey && featuredApiKeys.has(apiKey),
		[featuredApiKeys]
	);

	const routeHasNoExcludedFlag = useCallback(
		(route: TPathOptions | SubMenuOptions) => {
			const excludedRouteFlags = new Set(route.excludedFor);
			const isRouteExcluded = appliedExcludingFlags.some((item) =>
				excludedRouteFlags.has(item)
			);
			return !isRouteExcluded;
		},
		[appliedExcludingFlags]
	);

	const routeEnabledForBusinessSettings = useCallback(
		(path: string) => filterPaths(businessProperties)({ to: path }),
		[businessProperties]
	);

	const filterRoute = useCallback(
		(route: TPathOptions) =>
			isFeatureVisible(route.apiKey) &&
			routeHasNoExcludedFlag(route) &&
			routeEnabledForBusinessSettings(route.path),
		[routeHasNoExcludedFlag, routeEnabledForBusinessSettings]
	);

	const filterSubRoute = useCallback(
		(route: SubMenuOptions) =>
			(route.featureApiKey ? isFeatureVisible(route.featureApiKey) : true) &&
			routeHasNoExcludedFlag(route) &&
			routeEnabledForBusinessSettings(route.path) &&
			!route?.isExcluded?.({ businessProperties }),
		[
			routeHasNoExcludedFlag,
			routeEnabledForBusinessSettings,
			businessProperties,
		]
	);

	const configureRoute = useCallback(
		(baseRoute: TPathOptions): TPathOptions => {
			const filteredSubMenus = baseRoute.subMenu
				?.filter(filterSubRoute)
				?.map((subRoute) => ({
					...subRoute,
					isFeatured: isRouteFeatured(subRoute.featureApiKey),
				}));

			return {
				...baseRoute,
				subMenu: filteredSubMenus,
				isFeatured: isRouteFeatured(baseRoute.apiKey),
			};
		},
		[filterSubRoute, isRouteFeatured]
	);

	const filteredMainMenu = useMemo<TPathOptions[]>(
		() => appMainMenus.filter(filterRoute).map(configureRoute),
		[filterRoute, configureRoute]
	);

	const filteredOwnApp = useMemo<TPathOptions[]>(
		() => ownAppMenus.filter(filterRoute).map(configureRoute),
		[filterRoute, configureRoute]
	);

	const filteredSectioned = useMemo<TPathOptions[]>(
		() => sectionedMenus.filter(filterRoute).map(configureRoute),
		[filterRoute, configureRoute]
	);

	return {
		filteredMainMenu,
		filteredOwnApp,
		filteredSectioned,
	};
};
