import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import * as Yup from 'yup';

export const SubscriptionsRoutes = {
	SUBSCRIPTIONS: route(
		'subscriptions',
		{},
		{
			LIST: route('list', {}),
			CATEGORIES: route('categories', {}),
			ADD_SUBSCRIPTION: route('add-subscription', {}),
			VIEW: route('view/:uuid', {
				params: {
					uuid: yup(Yup.string().uuid()),
				},
			}),
		}
	),
};
