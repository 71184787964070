import { useMutation } from '@app/QueryClient';
import { apiUpdateOnboardingSettings } from '@app/Services/Tour/updateOnboardingKey';

export function useUpdateOnboardingKeys(opt?: any) {
	const mutation = useMutation(apiUpdateOnboardingSettings, {
		disableToaster: true,
		...opt,
	});

	const { mutate } = mutation;

	return {
		...mutation,
		doUpdateOnboardingKeys: mutate,
	};
}
