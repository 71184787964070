import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';

export const CardLinkSetupPathEntry: IModuleOptions = {
  ...pathOpt,
  routes: [
    {
      path: ROUTES.CARDLINK_SETUP.path,
      element: lazy(() => import('./CardLink')),
      aclProps: {
        aclGrants: [
          'view:cardlink setup - branch referencing',
          'view:cardlink setup - customer messaging',
          'view:cardlink setup - terminal customization',
          'view:cardlink setup - webhook registration',
        ],
      },
    },
  ],
};