import { CustomStepProps } from '@zeal/web-ui';

export const teamAdminsSteps: (
	t: (key: string) => string
) => CustomStepProps[] = (t) => [
	{
		selector: '#team-admins',
		title: t('team_admins_title'),
		description: t('team_admins_description'),
		arrowPosition: 'left',
		tourPlace: 'right',
		tourOverrideStyles: '!top-[-85px] !left-[15px]',
	},
	{
		selector: '#team-add-admin',
		title: t('add_admin_title'),
		description: t('add_admin_description'),
		arrowPosition: 'right',
		tourPlace: 'left',
	},
	{
		selector: '#team-admin-drawer',
		title: t('add_admin_drawer_title'),
		description: t('add_admin_drawer_description'),
		arrowPosition: 'right',
		tourPlace: 'left',
		tourOverrideStyles: '!top-[50px] !left-[-20px]',
	},
	{
		selector: '#team-admin-table',
		title: t('success_adding_admin_title'),
		description: t('success_adding_admin_description'),
		arrowPosition: 'top-center',
		tourPlace: 'bottom',
	},
];
