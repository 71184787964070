import { getLoggedInSession } from '@app/ACL/useLogin';
import { useTranslation } from 'react-i18next';

export const useAvatarInfo = () => {
	const { name, accessLevel, businessName, isSuperAdmin } =
		getLoggedInSession();
	const { t } = useTranslation('navbar');

	const shownAccessLevel = isSuperAdmin ? t('admin') : accessLevel;

	const displayAccessLevel = shownAccessLevel ?? t('noAccessLevel');
	const userName = name ?? businessName;
	const displayName = name ?? businessName ?? t('noName');

	return { displayAccessLevel, userName, displayName };
};
