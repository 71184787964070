import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const customersPathOptions: TPathOptions = {
	apiKey: 'customers',
	path: ROUTES.CUSTOMERS_MANAGEMENT.path,
	label: 'customers',
	iconName: 'user',
	menuOrder: 5,
	ownApp: false,
	subMenu: [
		{
			featureApiKey: 'customers',
			path: ROUTES.CUSTOMERS_MANAGEMENT.path,
			label: 'customers',
		},
	],
};

export default customersPathOptions;
