import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const tipsPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.TIPS.path,
			element: <Outlet />,
			aclProps: {
				aclGrants: ['teams:tip_distribution'],
			},
			children: [
				{
					index: true,
					element: <Navigate to={ROUTES.TIPS.HISTORY.path} />,
				},
				{
					path: ROUTES.TIPS.$.HISTORY.relativePath,
					element: lazy(() => import('./Tips')),
				},
				{
					path: ROUTES.TIPS.$.DISTRIBUTION.relativePath,
					element: lazy(() => import('./components/TipDistribution')),
					aclProps: {
						aclGrants: ['superAdmin'],
					},
				},
			],
		},
	],
};
