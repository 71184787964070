import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const teamsPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.TEAMS.path,
			element: <Outlet />,
			aclProps: {
				aclGrants: ['superAdmin'],
			},
			children: [
				{
					index: true,
					element: <Navigate to={ROUTES.TEAMS.DASHBOARD.path} />,
				},

				{
					path: ROUTES.TEAMS.$.TEAM_APP.relativePath,
					element: <Outlet />,
					children: [
						{
							index: true,
							element: lazy(() => import('./components/TeamApp')),
						},
						{
							path: ROUTES.TEAMS.TEAM_APP.$.ADD.relativePath,
							element: lazy(() => import('./components/AddEditTeamApp')),
						},
						{
							path: ROUTES.TEAMS.TEAM_APP.$.EDIT.relativePath,
							element: lazy(() => import('./components/AddEditTeamApp')),
						},
					],
				},

				{
					path: ROUTES.TEAMS.$.DASHBOARD.relativePath,
					element: <Outlet />,
					children: [
						{
							index: true,
							element: lazy(() => import('./components/Dashboard')),
						},
						{
							path: ROUTES.TEAMS.DASHBOARD.$.CREATE_ADMIN.relativePath,
							element: lazy(() => import('./components/AddEditBusinessAdmin')),
						},
						{
							path: ROUTES.TEAMS.DASHBOARD.$.EDIT_ADMIN.relativePath,
							element: lazy(() => import('./components/AddEditBusinessAdmin')),
						},
					],
				},

				{
					path: ROUTES.TEAMS.$.ROLES.relativePath,
					element: <Outlet />,
					children: [
						{
							index: true,
							element: lazy(() => import('./components/Roles')),
						},
						{
							path: ROUTES.TEAMS.ROLES.$.CREATE.relativePath,
							element: lazy(() => import('./components/AddEditRoles')),
						},
						{
							path: ROUTES.TEAMS.ROLES.$.EDIT.relativePath,
							element: lazy(() => import('./components/AddEditRoles')),
						},
					],
				},

				{
					path: ROUTES.TEAMS.TIP_DISTRIBUTION.path,
					element: lazy(() => import('../Tip/components/TipDistribution')),
				},
			],
		},
	],
};
