import React, { useMemo } from 'react';
import { ImageWrapper } from '../StyledComponents';
import { Image } from '@zeal/web-ui';
import DashboardLogoLayout from './DashboardLogoLayout';
import { useAppState } from '@app/store/appStore';
import { useBusinessInfo } from '@app/BusinessAccount/useBusinessInfo';
import NavbarInitials from './NavbarInitials';

const CustomAppDashboardLogo = () => {
	const { customAppName } = useAppState();
	const { data } = useBusinessInfo();
	const photoLink = data?.business?.photo;

	const customAppImage = useMemo(
		() => (
			<ImageWrapper>
				<Image
					rounded="lg"
					width={48}
					height={48}
					src={photoLink}
					className="max-w-max"
				/>
			</ImageWrapper>
		),
		[photoLink]
	);

	const appLogo =
		photoLink != null ? (
			customAppImage
		) : (
			<NavbarInitials username={customAppName} />
		);

	return (
		<DashboardLogoLayout currentAppName={customAppName!} appLogo={appLogo} />
	);
};

export default CustomAppDashboardLogo;
