import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';

export const CardLinkActivityPathEntry: IModuleOptions = {
  ...pathOpt,
  routes: [
    {
      path: ROUTES.CARDLINK_ACTIVITY.path,
      element: lazy(() => import('./CardLinkActivity')),
      aclProps: {
        aclGrants: [
          'view:cardlink activity',
        ],
      },
    },
  ],
};