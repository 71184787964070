import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate } from 'react-router-dom';

export const analyticsPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: `${ROUTES.ANALYTICS.path}/*`,
			element: lazy(() => import('./AnalyticsViz')),
			aclProps: {
				aclGrants: ['view:analytics'],
				aclUnAuthProps: {
					replace: true,
				},
			},
		},
		{
			path: '/feedback/analytics',
			element: <Navigate to={ROUTES.ANALYTICS.path} />,
		},
	],
};
