import React, { useEffect, useState } from 'react';

import { generatedKeys } from '@app/data/queryKeysFactory';
import { useQuery } from '@app/QueryClient';
import { apiPermissions } from '@app/Services/TeamMembers/permissions';
import { TApiPermission } from '@modules/TeamMembers/types';

const { teamMembers } = generatedKeys;

export function usePermissions() {
	const [formattedPermissions, setFormattedPermissions] = useState<
		Record<string, string>
	>({});

	const q = useQuery<{ data: TApiPermission[] }>({
		skipOwnApp: true,
		queryFn: apiPermissions,
		staleTime: Infinity,
		keepPreviousData: true,
		queryKey: teamMembers.permissions({}),
	});

	const { data: permissionsData } = q;

	useEffect(() => {
		if (permissionsData) {
			const formattedData = permissionsData?.data?.reduce(
				(acc, cur) => ({ ...acc, ...formatPermission(cur) }),
				{}
			);

			setFormattedPermissions(formattedData);
		}
	}, [permissionsData, setFormattedPermissions]);

	React.useDebugValue(q);

	return { ...q, formattedPermissions };
}

function formatPermission(mainPermission: TApiPermission) {
	return (mainPermission?.permissions || []).reduce((acc, cur) => {
		// Extract the verb from the permission name.
		const permissionFirstWord = cur?.name?.split(' ')?.[0];

		const permissionKey =
			`${permissionFirstWord}:${mainPermission?.name}`.toLowerCase();
		const permissionKeyWithId = `${cur?.id}:${permissionKey}`.toLowerCase();

		return {
			...acc,
			[permissionKey]: `${cur?.id}`,
			[permissionKeyWithId]: `${cur?.id}`,
		};
	}, {});
}
