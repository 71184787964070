import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const wheelOfFortunePathOptions: TPathOptions = {
	apiKey: 'wheel_of_fortune',
	path: ROUTES.WHEEL_OF_FORTUNE.path,
	label: 'wheeloffortune',
	iconName: 'tireFlat',
	menuOrder: 7,
	ownApp: true,
	excludedFor: [],
	subMenu: [
		{
			path: ROUTES.WHEEL_OF_FORTUNE.WHEELS.path,
			label: 'wheels',
		},
		{
			path: ROUTES.WHEEL_OF_FORTUNE.ARCHIVE.path,
			label: 'archive',
		},
		{
			path: ROUTES.WHEEL_OF_FORTUNE.REPORT.path,
			label: 'reports',
		},
	],
};

export default wheelOfFortunePathOptions;
