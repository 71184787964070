import React from "react";
import { ErrorFallback } from "./Components";
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    console.group("AppErrorBoundary");
    console.error(error);
    console.groupEnd();
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.group("AppErrorBoundary");
    console.error(error, errorInfo);
    console.groupEnd();
  }
  render() {
    if (this.state.hasError || this.props.forceErrorRender) {
      if (this.fallback) {
        return this.fallback;
      } else {
        return /* @__PURE__ */ React.createElement(ErrorFallback, null);
      }
    }
    return this.props.children;
  }
}
