export enum EMagicLinkType {
	VERIFICATION = 2,
	REGISTRATION = 3,
}

export type TApiGetEmaiResponse = {
	email: string;
	type: EMagicLinkType;
};

export type TMagicLinkForm = {
	type: EMagicLinkType;
	token: string;
	email: string;
	code: string;
	password: string;
	password_confirmation: string;
	accepted_terms: boolean;
	accepted_privacy_policy: boolean;
};

export enum EMagicLinkStep {
	EmailRegistration = 1,
	CodeVerification = 2,
	CreatePassword = 3,
	VerificationSent = 4,
	LoginToAccount = 5,
	AccountVerified = 6,
}

export type TMagicLinkFormContext = {
	step: EMagicLinkStep;
	goToNextStep: () => void;
	errors: Record<string, any>;
	setErrors: (value: Record<string, any>) => void;
	setStep: (value: EMagicLinkStep) => void;
	verificationEmail: string;
	setVerificationEmail: (value: string) => void;
};

export type TLoginForm = {
	email: string;
	password: string;
	keepSignIn: boolean;
};
