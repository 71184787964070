import * as Sentry from '@sentry/react';

export const ORDER_AUDIO_ID = 'orderNotificationAudio';

export const useNotificationSound = () => {
	const play = () => {
		const audio = document.getElementById(ORDER_AUDIO_ID) as HTMLAudioElement;
		try {
			audio?.load();
			audio?.play();
		} catch (error) {
			console.log('error: ', error);
			Sentry.captureException(error, {
				tags: {
					source: 'useOrderNotification',
				},
			});
		}
	};

	const stop = () => {
		const audio = document.getElementById(ORDER_AUDIO_ID) as HTMLAudioElement;

		audio?.pause();
	};

	return { play, stop };
};
