import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { Flex, Header, Label, palette, Tabs } from '@zeal/web-ui';

export const AppHeader = styled(Header)`
	grid-area: breadcrumbs;
`;

export const Content = styled.div`
	grid-area: appContent;
	max-height: calc(100vh - var(--main-headerbar-height));
	overflow-y: auto;
	margin: 0 !important;
	padding: 0 !important;
`;

const buttonLTRStyle = `left: calc(var(--main-navbar-width) - 0.9rem);`;

const buttonRTLStyle = `right: calc(var(--main-navbar-width) - 0.9rem); transform: scale(0.75) rotate(180deg);`;

export const CollapseButton = styled.div`
	position: absolute;
	cursor: pointer;
	top: 6rem;
	z-index: 9;
	display: flex;
	height: 1.7em;
	width: 1.7em;
	border-radius: 50%;
	border: 1px solid rgba(229, 231, 235, var(--tw-border-opacity));
	box-shadow: 0 0 3px rgba(145, 147, 164, 0.16);
	background: white;
	transform: scale(0.75);

	${(props) => (props.dir == 'rtl' ? buttonRTLStyle : buttonLTRStyle)}
`;

export const MainGrid = styled(motion.div)`
	background: ${palette.background.default};
	display: grid;
	grid-template-columns: var(--main-navbar-width) calc(
			100vw - var(--main-navbar-width)
		);
	grid-template-rows: var(--main-headerbar-height) calc(
			100vh - var(--main-headerbar-height)
		);
	grid-template-areas:
		'breadcrumbs breadcrumbs'
		'menu appContent';

	${css`
		--main-headerbar-height: 5.5rem;
	`}
`;

export const BorderLessTabs = styled(Tabs)`
	> div {
		border: 0;
	}
	padding-bottom: 0;
`;

export const TabsList = styled(Tabs.List)`
	margin-bottom: 0;
	position: relative;
	bottom: 0.25rem;
`;

export const OwnAppContainer = styled.div`
	width: 13.75rem;
`;

export const LanguageSelectorContainer = styled.div`
	width: auto;
`;

export const LanguageSelectorWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: DM Sans;
	font-weight: 400;
`;

export const StyledContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 12px 16px;
	padding-inline-end: 0;
	max-height: 52px;
	background: #ffffff;
	border: 1px solid #545d69;
	border-radius: 8px;
`;

export const StyledSpan = styled.span`
	border-inline-end: 1px solid #f4f4f5;
	padding-inline-end: 16px;
`;

export const StyledFreshDeskButton = styled.button`
	background: rgb(0, 96, 99);
	color: white;
	border-radius: 30px 8px 30px 30px;
	width: fit-content;
	padding: 8px;
	font-size: 0.875rem;
	span {
		margin: 0px 8px 2px 2px;
	}
`;

export const LetterWrapper = styled(Flex)`
	display: flex;
	width: 48px;
	height: 48px;
	padding: 12px 6px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
`;

export const ImageWrapper = styled.div`
	display: flex;
	width: 48px;
	height: 48px;
	padding: 12px 6px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
`;

export const VerticalDivider = styled.span`
	margin-block: auto;
	width: 1px;
	height: 36px;
	background: ${palette.divider.default};
`;

export const HorizontalDivider = styled.span`
	display: flex;
	height: 1px;
	background: #f4f4f5;
	border-radius: 2px;
`;

export const PointsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	gap: 8px;

	width: 110px;
	height: 40px;

	background: #ffffff;
	border-radius: 999px;

	cursor: pointer;
`;

export const ZealBalanceIconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 18px;
	height: 18px;
	padding: 0px;
	margin: 0px;

	border-width: 2px;
	border-color: #00dfac;
	border-radius: 100%;
`;

export const PageTitleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 24px;
	overflow: hidden;
	& > label {
		overflow: hidden;
		text-overflow: ellipsis;
		text-wrap: nowrap;
	}
`;

export const StyledPopoverPointer = styled.span`
	display: flex;
	width: 30px;
	height: 30px;
	position: absolute;
	background: #fff;
	transform: rotate(45deg);
	justify-content: center;
	align-items: center;
	left: 40%;
	top: 91%;
	z-index: 1;
`;

export const StyledFreemiumLanguageTrigger = styled(Flex)(
	({ isClosed }: React.PropsWithChildren<{ isClosed?: boolean }>) => [
		css`
			background: ${!isClosed ? palette.surface.pressed : 'transparent'};
			&:hover {
				background: ${palette.surface.pressed};
			}
		`,
	]
);
export const StyledAvatarContainer = styled(Flex)`
	padding: 0.5rem 0.75rem 0.5rem 0.75rem;
	background-color: #f6f6f7;
	border-radius: 9999px;
	overflow: hidden;
`;

export const StyledAvatarLabel = styled(Label)`
	line-height: 1.25rem !important;
	flex-grow: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap: nowrap;
`;

export const DashboardWrapper = styled(Flex)`
	max-width: calc(18.5rem - 31px) !important;
	min-width: fit-content;
`;

export const AvatarLetterWrapper = styled(Flex)`
	align-items: center;
	padding: 8px;
	width: 40px;
	height: 40px;
	min-width: 40px;
	border-radius: 999px;
`;
