import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const premiumDashboardPathOptions: TPathOptions = {
	apiKey: 'premium_dashboard',
	path: ROUTES.PREMIUM_DASHBOARD.path,
	label: 'home',
	iconName: 'house',
	menuOrder: 2,
	ownApp: false,
	excludedFor: [],
};

export default premiumDashboardPathOptions;
