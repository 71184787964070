import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import * as Yup from 'yup';

export const OrdersRoutes = {
	ORDERS: route(
		'orders',
		{},
		{
			LIST: route(
				'list',
				{},
				{
					DETAILS: route(':orderId', {
						params: {
							orderId: yup(Yup.string().required()),
						},
					}),
				}
			),
			CALENDAR: route('calendar', {}),
		}
	),
};
