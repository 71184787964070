import { useDebugValue } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery, useMutation } from '@app/QueryClient';

import { generatedKeys } from '@app/data';
import {
	giftFriendRequestStatus,
	requestGiftFriendFeature,
} from '@services/GiftFriend';

const { giftFriend } = generatedKeys;

export function useRequestGiftFriendFeature(opt: any) {
	const { t } = useTranslation('giftfriend');

	const mutation = useMutation(requestGiftFriendFeature, {
		...opt,
		moduleName: t('request_gift'),
		operation: 'add',
	});
	const { mutate } = mutation;

	return {
		...mutation,
		doRequestGiftFriendFeature: mutate,
	};
}

export function useGiftFriendRequestStatus() {
	const q = useQuery({
		queryFn: giftFriendRequestStatus,
		staleTime: 1000 * 60 * 15, //15 min
		queryKey: giftFriend.requestStatus({}),
	});

	useDebugValue(q);

	return q;
}
