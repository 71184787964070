import React from 'react';
import { useAppState } from '@app/store/appStore';
import { useLoggedIn } from '@app/ACL/useLogin';
import CustomAppDashboardLogo from './CustomAppDashboardLogo';
import BusinessDashboardLogo from './BusinessDashboardLogo';
import DefaultDashboardLogo from './DefaultDashboardLogo';

export default function DashboardLogo() {
	const { isCustomAppActive } = useAppState();
	const { merchantType } = useLoggedIn();

	if (!!merchantType) {
		return <BusinessDashboardLogo />;
	}

	if (isCustomAppActive) {
		return <CustomAppDashboardLogo />;
	}

	return <DefaultDashboardLogo />;
}
