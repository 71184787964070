import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const VisitTransactionsPathEntry: IModuleOptions = {
  ...pathOpt,
  routes: [
    {
      path: ROUTES.VISITS_MANAGEMENT.path,
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <Navigate to={ROUTES.VISITS_MANAGEMENT.TRANSACTIONS.path} replace />,
        },
        {
          path: ROUTES.VISITS_MANAGEMENT.$.TRANSACTIONS.relativePath,
          element: lazy(() => import('./VisitTransactions')),
          aclProps: {
            aclGrants: ['view:transactions'],
          },
        },
        {
          path: ROUTES.VISITS_MANAGEMENT.$.RECEIPTS.relativePath,
          element: lazy(() => import('../Receipts/Receipts')),
          aclProps: {
            aclGrants: ['view:transactions'],
          },
        },
        // {
        //   path: ROUTES.VISITS_MANAGEMENT.RECEIPTS.$.PAGE.relativePath,
        //   element: lazy(() => import('../Receipts/Receipt')),
        //   aclProps: {
        //     aclGrants: ['view:transactions'],
        //   },
        // },
        // {
        //   path: ROUTES.VISITS_MANAGEMENT.$.REDEMPTIONS.relativePath,
        //   element: lazy(() => import('../Redemptions/Redemptions')),
        //   aclProps: {
        //     aclGrants: ['view:transactions'],
        //   },
        // },
      ],
    },
  ],
};

VisitTransactionsPathEntry;