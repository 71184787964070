import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { ROUTES } from '@app/AppRoutes/routes';
import { Outlet } from 'react-router-dom';
import ACLBoundary from '../App/ACL/ACLBoundary';
import { IModuleOptions } from '@app/types/types';

export const TechniciansSlotsPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.TECHNICIANS_SLOTS.path,
			element: lazy(() => import('./TechniciansSlots')),
      aclProps:{
        aclUnAuthProps: { replace: true },
        aclGrants: ['view:schedule']
      }
		},
	],
};

