import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const freemiumLoyaltyPathOptions: TPathOptions = {
	apiKey: 'loyalty',
	exact: false,
	menuOrder: 3,
	ownApp: false,
	path: ROUTES.FREEMIUM_LOYALTY.path,
	label: 'loyalty_program',
	excludedFor: [],
	iconName: 'gift',
	subMenu: [
		{
			featureApiKey: 'loyalty:loyalty_settings',
			label: 'loyalty_settings',
			path: ROUTES.FREEMIUM_LOYALTY.SETTINGS.path,
		},
		{
			featureApiKey: 'loyalty:loyalty_reports',
			label: 'loyalty_reports',
			path: ROUTES.FREEMIUM_LOYALTY.REPORTS.path,
		},
		{
			featureApiKey: 'loyalty:loyalty_downloads',
			label: 'downloads',
			path: ROUTES.FREEMIUM_LOYALTY.DOWNLOADS.path,
		},
	],
};

export default freemiumLoyaltyPathOptions;
