import { ApiRequest } from '@zeal/zeal-lib';
import { ENDPOINTS } from '../endpoints';
import { TApiOnboardingSettings } from '@app/Tour/types';

export async function apiListOnboardingSettings({ queryKey: [] }: any) {
	return new ApiRequest(ENDPOINTS.tour.listSettings)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-type', 'application/json')
		.GET<ApiResponse<TApiOnboardingSettings[]>>();
}
