import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import * as Yup from 'yup';

export const ExportsRoutes = {
	EXPORTS: route(
		'exports',
		{},
		{
			VISITS: route('visits', {}),
			CUSTOMERS: route('customers', {}),
			PROMO_CODES: route('promocodes', {}),
			WHEEL_OF_FORTUNE: route('wheelOfFortune', {}),
		}
	),
};
