import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDocumentDirection } from '@zeal/zeal-lib';

import LanguageSelector from './LanguageSelector';
import {
	LanguageSelectorWrapper,
	StyledContainer,
	StyledSpan,
} from './StyledComponents';

interface Props {
	isLoginPage?: boolean;
}

const LoginLanguageSelector: React.FC<Props> = ({ isLoginPage }) => {
	const { t } = useTranslation('common');
	const dir = useDocumentDirection();

	return (
		<LanguageSelectorWrapper dir={dir}>
			<StyledContainer>
				<StyledSpan>{t('change_language')}</StyledSpan>
				<LanguageSelector isLoginPage={isLoginPage} />
			</StyledContainer>
		</LanguageSelectorWrapper>
	);
};

export default LoginLanguageSelector;
