import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, InlineLoading } from '@zeal/web-ui';

import UnAuth from '../UnAuth';

import useGrants from './useGrants';
import { Permissions } from '../store/authStore';

export type TUnAuthProps = {
	replace?: boolean;
} & {
	[key: string]: any;
};

export interface ACLBoundaryProps {
	unAuthComponent?: React.FC<{}>;
	aclGrants?: string[];
	aclUnAuthProps?: TUnAuthProps;
	asElement?: any;
}

export default function ACLBoundary(
	props: React.PropsWithChildren<ACLBoundaryProps>
) {
	const { unAuthComponent, children, aclGrants, asElement, aclUnAuthProps } =
		props;

	const UnAuthComponent = unAuthComponent || UnAuth;

	const {
		userGrants,
		isSuperAdmin,
		allFormattedGrants,
		isFetchingPermissions,
	} = useGrants();

	const { t } = useTranslation('common');

	// if permissions query is loading return loading component
	if (isFetchingPermissions) {
		const isComponentReplaceable = aclUnAuthProps?.replace;
		return (
			<div className={isComponentReplaceable ? 'h-screen' : 'h-full'}>
				<Flex justify="center" align="center" className="h-full">
					<InlineLoading label={t('loading')} />
				</Flex>
			</div>
		);
	}

	const changedChildren = React.Children.map(children, (child) => {
		if (!React.isValidElement(child)) {
			return;
		}

		const childGrants = [
			...(child?.props?.aclGrants || []),
			...(aclGrants || []),
		];

		const isChildRequiresGrants = childGrants.length === 0;

		const childUnAuthProps = child?.props?.aclUnAuthProps || aclUnAuthProps;

		const unAuthPropsInvalid =
			!childUnAuthProps || Object.keys(childUnAuthProps || {}).length === 0;

		if (unAuthPropsInvalid) {
			throw new Error('No aclUnAuthProps provided');
		}

		if (isChildRequiresGrants) {
			return child;
		}

		const isChildHaveUserGrant = (grant: Permissions['permissions'][0]) =>
			(childGrants || []).some(
				// @ts-ignore
				(childPermission) => allFormattedGrants[childPermission] == grant.id
			);

		// checks if user has any grant to access child
		const isAllowed = (userGrants || [])?.some(isChildHaveUserGrant);

		if (!isAllowed && !isSuperAdmin) {
			return manageChildState(child, UnAuthComponent, childUnAuthProps);
		}

		return removeGrantsProp(child as React.ReactElement<any>);
	});

	const AsElement = asElement || Fragment;

	return <AsElement>{changedChildren}</AsElement>;
}

function manageChildState(
	child: React.ReactElement,
	UnAuthComponent: React.FC<{}>,
	aclUnAuthProps: TUnAuthProps
) {
	if (aclUnAuthProps.replace) {
		return <UnAuthComponent />;
	} else {
		return React.cloneElement(child, { ...aclUnAuthProps });
	}
}

function removeGrantsProp(
	child: React.ReactElement<
		React.PropsWithRef<{
			aclGrants: any;
			ref: any;
		}>,
		string | React.JSXElementConstructor<any>
	>
) {
	const { aclGrants: removed, ref: childRef, ...childProps } = child.props;

	const childWithoutGrantsProp = (
		<child.type key={child.key} ref={childRef} {...childProps} />
	);

	return childWithoutGrantsProp;
}
