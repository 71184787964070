import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const rewardsPathOptions: TPathOptions = {
	apiKey: 'rewards',
	path: ROUTES.REWARDS.path,
	label: 'rewards',
	iconName: 'gift',
	menuOrder: 2,
	ownApp: false,
};

export default rewardsPathOptions;
