import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import * as Yup from 'yup';

export const TeamsRoutes = {
	TEAMS: route(
		'teams',
		{},
		{
			TEAM_APP: route('app',{},{
				ADD: route('add',{}),
				EDIT: route('edit/:uuid',{
					params:{
						uuid: yup(Yup.string().required())
					}
				}),
			}),

			DASHBOARD: route('dashboard',{},{
				CREATE_ADMIN: route('create-admin'),
				EDIT_ADMIN: route('edit-admin',{
					searchParams:{
						adminId: yup(Yup.string().required())
					}
				}),
			}),

			ROLES: route('roles',{},{
				CREATE: route('create'),
				EDIT: route('edit',{
					searchParams:{
						roleId: yup(Yup.string().required())
					}
				}),
			}),

			TIP_DISTRIBUTION: route('tip-distribution'),
		}
	),
};
