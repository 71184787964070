import React from 'react';
import { Navigate } from 'react-router-dom';

function UnAuthorized(props) {
	const { unauthorizedRender, unauthorizedComponent, unauthorizedRedirect } =
		props;

	if (unauthorizedRender) {
		return unauthorizedRender?.();
	}

	if (unauthorizedRender) {
		const UnAuth = unauthorizedComponent;

		return <UnAuth {...props} />;
	}

	return <Navigate to={redirectToState(unauthorizedRedirect)} />;
}

const redirectToState = (pathname = '/login') => ({
	pathname: pathname,
	state: {
		unauthorizedRedirect: true,
		shouldGoBack: true,
	},
});

export default React.memo(UnAuthorized);
