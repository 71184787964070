import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export interface PageConfig {
	title?: JSX.Element | string | null; // title must be a memoized component or a string
	middleContent?: JSX.Element | string | null; // middleContent must be a memoized component or a string
}

export interface PageStore {
	appMenu: 'minimized' | 'open';
	changeAppMenuState: (state: 'minimized' | 'open') => void;
	toggleAppMenu: () => void;
	setPageConfig: (config: PageConfig) => void;
	pageConfig: PageConfig;
}

const initialState: Pick<PageStore, 'appMenu' | 'pageConfig'> = {
	appMenu: 'open',
	pageConfig: {
		title: null,
		middleContent: null,
	},
};

export const usePageStore = create<PageStore>()(
	devtools(
		(set, get) => ({
			...initialState,
			changeAppMenuState: (state) => {
				set({ appMenu: state });
			},
			toggleAppMenu: () => {
				const state = get().appMenu === 'minimized' ? 'open' : 'minimized';
				set({ appMenu: state });
			},
			setPageConfig: (newConfig) => {
				const config = get().pageConfig;

				set({
					pageConfig: {
						...config,
						...newConfig,
					},
				});
			},
		}),
		{ name: 'page-store' }
	)
);
