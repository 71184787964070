import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import * as Yup from 'yup';

export const BranchesRoutes = {
	BRANCHES: route(
		'branches',
		{},
		{
			BRANCH_ZONING: route(':id/branch-zoning', {
				params: {
					id: yup(Yup.string().required()),
				},
			}),
		}
	),
};
