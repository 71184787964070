import {
	TGuide,
	TStrapiResponse,
	TStrapiResponseArray,
} from '@modules/FreeOnboarding/types';
import { ApiRequest } from '@zeal/zeal-lib';

const apiToken = process.env.REACT_APP_STRAPI_TOKEN;
const apiURL = process.env.REACT_APP_STRAPI_URL ?? '';

export const strapiGuidesURL = `${apiURL}/api/free-home-guides`;

export async function apiGuides(lang: string, pageParam: number) {
	return new ApiRequest(strapiGuidesURL)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.addHeader('Authorization', `Bearer ${apiToken}`)
		.addQuery('locale', lang)
		.addQuery('pagination[pageSize]', '100')
		.addQuery('pagination[page]', pageParam?.toString())
		.addQuery('populate', '*')
		.GET<TStrapiResponseArray<TGuide>>({
			noAuth: true,
		})
		.then((payload) => payload);
}

export async function apiGuide(lang: string, id: string) {
	return new ApiRequest(`${apiURL}/api/free-home-guides/${id}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.addHeader('Authorization', `Bearer ${apiToken}`)
		.addQuery('locale', lang)
		.addQuery('populate', '*')
		.addQuery('populate[0]', 'guide.media')
		.addQuery('populate[content][populate]', '*')
		.GET<TStrapiResponse<TGuide>>({
			noAuth: true,
		})
		.then((payload) => payload);
}
