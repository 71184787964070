import { route } from 'react-router-typesafe-routes/dom';

export const FreemiumTeamsRoutes = {
	FREEMIUM_TEAMS: route(
		'administration',
		{},
		{
			ADMINISTRATORS: route('administrators', {}),
			TEAMS: route('teams', {}),
		}
	),
};
