import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const cardlinkPathOptions: TPathOptions = {
	apiKey: 'cardlink_dashboard',
	path: ROUTES.CARDLINK_SETUP.path,
	label: 'cardlink_setup',
	iconName: 'creditCard',
	menuOrder: 2,
	ownApp: false,
	menuSection: 'cardlink',
  excludedFor: [],
};

export default cardlinkPathOptions;
