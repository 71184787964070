import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { setLocale as setYupLocale } from "yup";
import { clarity } from "clarity-js";
import { ApiRequest } from "@zeal/zeal-lib";
import { getLoggedInSession } from "./App/ACL/useLogin";
import { onLogout } from "./App/AppMenu";
import i18next from "./i18n";
import "./firebase";
import {
  getAccountInfo,
  refetchUserPermissionsEvent
} from "./App/BusinessAccount/useBusinessInfo";
import { t } from "i18next";
const API_HOST = process.env.REACT_APP_VENDOR_API_HOST;
Sentry.init({
  autoSessionTracking: true,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_ENV,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  // initialScope: scope => {
  // 	scope.setTags({ a: "b" });
  // 	return scope;
  // },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1
});
localStorage.setItem("dev/allow", process.env.ALLOW_DEV_TOOLS);
function attachAppIdOnRequest(error, apiRequest, response) {
  const isCmsRequest = (apiRequest?._path || "")?.includes(
    process.env.REACT_APP_STRAPI_URL
  );
  if (!error && !response && !isCmsRequest) {
    const { appId } = getAccountInfo();
    apiRequest.addHeader("AppId", appId);
    apiRequest.addHeader("App-Id", appId);
  }
}
function attachAuthHeaderOnRequest(error, apiRequest, response, { config }) {
  if (!error && !response && !config.noAuth) {
    const { authorization } = getLoggedInSession();
    apiRequest.addHeader("Authorization", authorization);
  }
}
function attachLanguageHeaderOnRequest(error, apiRequest, response, { config }) {
  if (!error && !response && !config.noLanguage) {
    const currentLanguage = i18next.language;
    apiRequest.addHeader("accept-language", currentLanguage);
  }
}
function logoutOn401Middleware(error) {
  if (error) {
    const statusCode = error?.response?.status;
    if (statusCode === 401) {
      onLogout();
      window.location.href = "/login";
    }
  }
}
function refreshGrantsOn403Middleware(error) {
  if (error) {
    const statusCode = error?.response?.status;
    if (statusCode === 403) {
      refetchUserPermissionsEvent().emit();
    }
  }
}
ApiRequest.setURL(API_HOST, "api/");
ApiRequest.use(attachAppIdOnRequest);
ApiRequest.use(attachAuthHeaderOnRequest);
ApiRequest.use(attachLanguageHeaderOnRequest);
ApiRequest.use(logoutOn401Middleware);
ApiRequest.use(refreshGrantsOn403Middleware);
(function initializeYupLocalization() {
  setYupLocale({
    mixed: {
      default: t("field_invalid"),
      required: t("field_required")
    },
    number: {
      min: ({ min }) => ({ key: t("number_too_short"), values: { min } }),
      max: ({ max }) => ({ key: t("number_too_big"), values: { max } })
    },
    date: {
      min: ({ min }) => ({ key: t("date_min"), values: { min } }),
      max: ({ max }) => ({ key: t("date_max"), values: { max } })
    }
  });
})();
(function startClarity() {
  if (process.env.ZEAL_ENV === "production") {
    clarity.start({
      projectId: "k06vr74wm0",
      upload: "https://m.clarity.ms/collect",
      track: true,
      content: true
    });
  }
})();
