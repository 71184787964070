import { route } from 'react-router-typesafe-routes/dom';

export const PremiumTransactionsRoutes = {
	PREMIUM_TRANSACTIONS: route(
		'premium-transactions',
		{},
		{
			TRANSACTIONS: route('transactions', {}),
			DOWNLOADS: route('downloads', {}),
		}
	),
};
