import { useState, useEffect, useCallback } from 'react';

import { useTourStore } from '@app/store/tourStore';

export function useTour({
	elementId,
	tourName,
}: {
	elementId: string;
	tourName: string;
}) {
	const [isElementPresent, setIsElementPresent] = useState(false);
	const { activeTour, setActiveTour } = useTourStore();

	const checkElementPresence = useCallback(() => {
		const element = document.getElementById(elementId);
		if (element) {
			setIsElementPresent(true);
			return true;
		}
		return false;
	}, [elementId]);

	useEffect(() => {
		let interval: ReturnType<typeof setInterval> | null = null;

		const startCheckingElement = () => {
			interval = setInterval(() => {
				if (checkElementPresence()) {
					clearInterval(interval!);
				}
			}, 1000);
		};

		startCheckingElement();

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [checkElementPresence]);

	useEffect(() => {
		if (activeTour !== tourName) {
			setIsElementPresent(false);
		}
	}, [activeTour, tourName]);

	return {
		isElementPresent,
		activeTour,
		setActiveTour,
	};
}
