import { useDebugValue, useEffect } from 'react';
import { ApiRequest, FeatureToggles } from '@zeal/zeal-lib';

import { useLoggedIn } from '../ACL/useLogin';
import { useAppStore } from '../store/appStore';
import { useAuthStore } from '@app/store/authStore';
import { ENDPOINTS } from '@app/Services/endpoints';
import { useQuery } from '@tanstack/react-query';

export type AccountInfoParams = {
	id?: string;
	name?: string;
	hasOwnApp?: string;
};
export type AccountInfo = {
	appId: string;
	appName: string | null;
	hasOwnApp: boolean;
	isAppOwn: boolean;
};

async function apiBusinessId() {
	return new ApiRequest('me/app')
		.addHeader('Accept', 'application/json')
		.GET<{
			appId: string;
			hasWhitelabel: boolean;
		}>()
		.then((data) => {
			data.appId = data.appId?.toString();

			return data;
		});
}

export default function useBusinessId() {
	const { isLoggedIn } = useLoggedIn();

	const q = useQuery({
		queryFn: apiBusinessId,
		staleTime: Infinity,
		queryKey: ['z-account-id'],
		enabled: isLoggedIn && !!useAuthStore.getState().loginVault?.id,
	});

	useDebugValue(q);

	return q;
}

export type BusinessInfoAPI = {
	uuid: string;
	name: string;
	email: string;
	created_at: string;
	all_branches: unknown;
	is_super_admin: boolean;
	credit: number;
	business: {
		uuid: string;
		name: string;
		photo: string;
		cover: string;
		settings: {
			tap_to_pay_enabled: boolean;
			has_only_one_menu: boolean;
			pos_integration_enabled: boolean;
		};
		terminal_settings: {
			feedback_enabled: boolean;
			card_triggered_enabled: boolean;
			micro_loyalty_enabled: boolean;
		};
	};
};

async function apiBusinessInfo(): Promise<BusinessInfoAPI> {
	return new ApiRequest(ENDPOINTS.business.info)
		.addHeader('Accept', 'application/json')
		.GET()
		.then((res) => {
			const data: BusinessInfoAPI = res?.data || {};

			FeatureToggles.init(
				{
					core: {
						key: data.email,
						// @ts-ignore
						trafficType: 'users',
						authorizationKey: process.env.REACT_APP_SPLIT_API_KEY ?? '',
					},
				},
				{
					name: data.business?.name,
					email: data.email,
					portal: 'zeal-portal',
				}
			);

			return data;
		});
}

export function useBusinessInfo() {
	const { isLoggedIn } = useAuthStore((store) => store);

	const q = useQuery({
		queryFn: apiBusinessInfo,
		staleTime: Infinity,
		queryKey: ['z-account-info'],
		enabled: isLoggedIn,
	});

	useDebugValue(q);

	return { ...q };
}

export function getAccountInfo(): AccountInfo {
	const { appState } = useAppStore.getState();

	return {
		appId: (appState?.currentAppId ?? 1)?.toString(),
		appName: appState?.currentAppName ?? 'zeal',
		hasOwnApp: appState?.hasCustomApp ?? false,
		isAppOwn: appState?.isCustomAppActive ?? false,
	};
}

export function useAccountInfo() {
	const { data: customAppData, isLoading: isBusinessIdLoading }: any =
		useBusinessId();
	const { data: businessInfo, isLoading: isBusinessInfoLoading } =
		useBusinessInfo();

	const { updateAppState, appState, activateCustomApp } = useAppStore(
		(state) => state
	);

	const updateAccountInfo = (info: { appId: string; appName: string }) => {
		updateAppState({
			isCustomAppActive: info.appId !== '1',
			currentAppName: info.appName,
			currentAppId: info.appId,
		});
	};

	useEffect(() => {
		if (customAppData) {
			const hasWhitelabel = !!(customAppData as any)?.hasWhitelabel;

			updateAppState({
				hasCustomApp: hasWhitelabel,
				customAppId: customAppData?.appId,
			});
		}
	}, [(customAppData as any)?.hasWhitelabel]);

	useEffect(() => {
		if (businessInfo) {
			updateAppState({
				customAppName: businessInfo?.business?.name,
			});

			if (!appState?.isSwitchedOnLoad) {
				if (appState?.hasCustomApp && !appState.isCustomAppActive) {
					activateCustomApp({
						appId: (appState?.customAppId || '1')?.toString(),
						appName: businessInfo?.business?.name,
					});
				} else {
					updateAppState({
						isCustomAppActive: false,
						currentAppId: '1',
						currentAppName: 'zeal',
						isSwitchedOnLoad: true,
					});
				}
			}
		}
	}, [businessInfo ?? {}]);

	return {
		updateApp: updateAccountInfo,
		isLoading: isBusinessIdLoading || isBusinessInfoLoading,
	};
}

async function apiBusinessPermissions() {
	return new ApiRequest('v3/permissions/business')
		.addHeader('Accept', 'application/json')
		.GET()
		.then(({ data }) => {
			const { updateCachedUserPermissions } = useLoggedIn();
			updateCachedUserPermissions(data);

			return data;
		});
}

const REFETCH_PERMISSIONS_EVENT = 'refetch-permissions-event';

export function refetchUserPermissionsEvent() {
	return {
		emit() {
			const evt = new CustomEvent(REFETCH_PERMISSIONS_EVENT, {
				detail: { status: 403 },
			});
			window.dispatchEvent(evt);
		},
		on(handler: any) {
			window.addEventListener(REFETCH_PERMISSIONS_EVENT, handler);

			return () => {
				window.removeEventListener(REFETCH_PERMISSIONS_EVENT, handler);
			};
		},
	};
}

export function useBusinessPermissions() {
	const q = useQuery({
		queryFn: apiBusinessPermissions,
		staleTime: Infinity,
		queryKey: ['z-business-permissions'],
	});

	refetchUserPermissionsEvent().on((evt: any) => {
		q.refetch();
	});

	useDebugValue(q);

	return q;
}

export interface BusinessProperties {
	booking: boolean;
	payment: boolean;
	ordering: boolean;
	loyalty: boolean;
	gift_feature: boolean;
	business_day_payment_reports: boolean;
	business_day_branch_payment_reports: boolean;
	team: boolean;
	push_notification: boolean;
	currency: string;
	order_acceptance_status_by: string;
	locale: string;
}

async function apiBusinessProperties() {
	return new ApiRequest('v3/business/properties')
		.addHeader('Accept', 'application/json')
		.GET<ApiResponse<BusinessProperties>>()
		.then(({ data }) => data);
}

export function useBusinessProperties() {
	const { isLoggedIn } = useLoggedIn();

	const q = useQuery({
		queryFn: apiBusinessProperties,
		staleTime: Infinity,
		queryKey: ['z-business-properties'],
		enabled: isLoggedIn && !!useAuthStore.getState().loginVault?.id,
	});

	useDebugValue(q);

	return q;
}
