import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const galleryPathOptions: TPathOptions = {
	apiKey: 'gallery',
	exact: false,
	path: ROUTES.GALLERY.path,
	label: 'gallery',
	iconName: 'image',
	menuOrder: 4,
	ownApp: true,
	excludedFor: [],
	subMenu: [
		{
			path: ROUTES.GALLERY.PHOTOS.path,
			label: 'photos',
		},
		{
			path: ROUTES.GALLERY.VIDEOS.path,
			label: 'videos',
		},
	],
};

export default galleryPathOptions;
