import { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';

export const DashboardPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.DASHBOARD.path,
			element: lazy(() => import('./Dashboard')),
			children: [
				{
					path: ROUTES.DASHBOARD.$.BACK_TO_DASHBOARD.relativePath,
					element: lazy(() => import('./BackToDashboard')),
				},
			],
		},
	],
};
