import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const ConsumerReportsPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.CONSUMER_REPORTS.path,
			element: lazy(() => import('./ConsumerReport')),
		},
	],
};
