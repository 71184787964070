import { Padding } from '@zeal/web-ui';
import { SectionHorizontalDivider } from './StyledComponents';
import React from 'react';

export default function SectionDivider() {
  return (
    <Padding padding={{ horizontal: '4', vertical: '3' }}>
      <SectionHorizontalDivider />
    </Padding>
  );
}
