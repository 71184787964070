import { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';

export const RecommendationsPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.RECOMMENDATIONS.path,
			element: lazy(() => import('./Recommendations')),
			aclProps: {
				aclGrants: ['view:recommendations'],
			},
		},
	],
};
