import React, { useMemo, useState } from 'react';
import { SwiperProps, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import BackgoundSVG from './assets/background.svg';
import CarouselImage1 from './assets/carousel-1.png';
import CarouselImage2 from './assets/carousel-2.png';
import CarouselImage3 from './assets/carousel-3.png';
import CarouselImage4 from './assets/carousel-4.png';
import {
	Flex,
	Image,
	Icon,
	Label,
	ParentShimmer,
	Grid,
	LayoutShimmer,
} from '@zeal/web-ui';
import { useFetchTranslation } from '@app/hooks';
import ZealIcon from './assets/zeal-icon.svg';
import ZealName from './assets/zeal-name.svg';
import { useTranslation } from 'react-i18next';
import { StyledSlider } from './styledComponents';
import { Autoplay, EffectFade } from 'swiper/modules';

export const APP_WEBSITE = process.env.REACT_APP_WEBSITE ?? '';

const carouselImages = [
	<Image
		src={CarouselImage1}
		className="w-full h-fit"
		style={{
			objectFit: 'contain',
		}}
	/>,
	<Image
		src={CarouselImage2}
		className="w-full h-fit"
		style={{
			objectFit: 'contain',
		}}
	/>,
	<Image
		src={CarouselImage3}
		className="w-full h-fit"
		style={{
			objectFit: 'contain',
		}}
	/>,
	<Image
		src={CarouselImage4}
		className="h-fit"
		style={{
			width: '90%',
		}}
	/>,
];

export default function CarouselCard() {
	const { isTranslationsLoading } = useFetchTranslation('magicLink');
	const { t } = useTranslation('magicLink');
	
	const [swiperRef, setSwiperRef] = useState<SwiperProps | null>(null);
	const [slide, setSlide] = useState(0);

	const cardTitle = useMemo(() => {
		return [
			t('carousel_1_title'),
			t('carousel_2_title'),
			t('carousel_3_title'),
			t('carousel_4_title'),
		];
	}, [t]);

	const cardSubtitle = useMemo(() => {
		return [
			t('carousel_1_subtitle'),
			t('carousel_2_subtitle'),
			t('carousel_3_subtitle'),
			t('carousel_4_subtitle'),
		];
	}, [t]);

	return (
		<Flex isColumn gap="4" className="p-6 h-screen relative">
			<ParentShimmer isLoading={isTranslationsLoading}>
				<Flex
					className="relative"
					isColumn
					style={{
						height: '98%',
					}}
				>
					<div
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							backgroundImage: `url(${BackgoundSVG})`,
							backgroundSize: 'cover',
							backgroundPosition: 'center',
							zIndex: -1,
							borderRadius: '12px',
						}}
					/>
					<Flex
						justify="between"
						align="center"
						className="w-full px-12 py-10 absolute top-0"
					>
						<Flex
							align="center"
							justify="center"
							style={{
								gap: '12.804px',
							}}
						>
							<Flex
								style={{
									width: '42.773px',
									height: '30.909px',
									flexShrink: 0,
								}}
							>
								<img src={ZealIcon} />
							</Flex>
							<Flex
								style={{ width: '44.679px', height: '16.448px', flexShrink: 0 }}
							>
								<img src={ZealName} />
							</Flex>
						</Flex>
						<a
							target="_blank"
							rel="noreferrer"
							href={APP_WEBSITE + '/merchant-landing'}
							className="z-10"
						>
							<Flex
								align="center"
								justify="center"
								className="cursor-pointer"
								style={{
									gap: '7px',
								}}
							>
								<Label.Mid300 variant="white">{t('learn_more')}</Label.Mid300>
								<Icon
									iconName="arrowRight"
									colorVariant="white"
									variant="regular"
								/>
							</Flex>
						</a>
					</Flex>
					<StyledSlider
						slidesPerView={1}
						loop
						autoplay={{
							delay: 20000,
							disableOnInteraction: false,
						}}
						speed={300}
						modules={[Autoplay, EffectFade]}
						effect="fade"
						fadeEffect={{
							crossFade: true,
						}}
						onSwiper={setSwiperRef}
						onSlideChange={(swiperCore: SwiperProps) => {
							setSlide(swiperCore?.activeIndex);
						}}
					>
						{[0, 1, 2, 3].map((index) => (
							<SwiperSlide key={index} data-testid="carousel-slide">
								<Flex isColumn gap="3" className="px-12 pt-12" align="center">
									<Label.Mid600
										variant="white"
										textAlign="center"
										className="self-stretch"
										style={{ width: '568px' }}
									>
										{cardTitle[index]}
									</Label.Mid600>
									<Label.Thin200
										variant="white"
										textAlign="center"
										style={{ width: '500px' }}
									>
										{cardSubtitle[index]}
									</Label.Thin200>
								</Flex>
								{carouselImages[index]}
							</SwiperSlide>
						))}
					</StyledSlider>
				</Flex>
			</ParentShimmer>
			<Grid cols={4} gap="3" className="w-full">
				<LayoutShimmer isLoading={isTranslationsLoading}>
					{[0, 1, 2, 3].map((index) => (
						<div
							key={index}
							className="h-2 rounded-lg cursor-pointer"
							data-testid={`pagination-button-${index}`}
							style={{
								background:
									slide === index ? '#1A215E' : 'rgba(147, 147, 147, 0.20)',
							}}
							onClick={() => swiperRef?.slideTo(index)}
						/>
					))}
				</LayoutShimmer>
			</Grid>
		</Flex>
	);
}
