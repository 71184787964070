import { route } from 'react-router-typesafe-routes/dom';

export const GiftFriendRoutes = {
	GIFT_FRIEND: route(
		'gift-friend',
		{},
		{
			GIFTS: route('gifts', {}),
			CATEGORIES: route('categories', {}),
			DISCOUNTS: route('discounts', {}),
		}
	),
};
