import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import * as Yup from 'yup';

export const SettingsRoutes = {
	SETTINGS: route(
		'settings',
		{},
		{
			ACCOUNT: route('account', {}),
			PROFILE: route('profile', {}),
			TAP_TO_PAY: route('tapToPay', {}),
			POS: route('pos', {}),
			SOCIAL_MEDIA: route('socialMedia', {}),
			SUBSCRIPTION: route('subscription', {}),
			CATEGORIES: route('categories', {}),
			BILLING: route('billing', {}),
			STRIPE: route('stripe', {}),
			PAYMENT_METHOD: route('paymentMethod', {}),
			BOOKING: route('booking', {}),
			REFUND: route('refund', {}),
			FEEDBACK: route('feedback', {}),
			POS_LANGUAGE: route('posLanguage', {}),
			WEBHOOK_SETTINGS: route('webhookSettings', {}),
			TERMS_AND_CONDITIONS: route('termsAndConditions', {}),
		}
	),
};
