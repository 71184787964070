import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Outlet } from 'react-router-dom';

export const BranchesPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.BRANCHES.path,
			aclProps: {
				aclGrants: ['view:branches'],
			},
			element: <Outlet />,
			children: [
				{
					index: true,
					element: lazy(() => import('./Branches')),
				},
				{
					path: ROUTES.BRANCHES.$.BRANCH_ZONING.relativePath,
					element: lazy(() => import('./components/BranchZoning/BranchZoning')),
				},
			],
		},
	],
};
