import { create } from 'zustand';

type TourStore = {
	activeTour: string | null;
	setActiveTour: (tourName: string | null) => void;
	resetTour: () => void;
};

export const useTourStore = create<TourStore>((set) => ({
	activeTour: null,
	setActiveTour: (tourName) => set({ activeTour: tourName }),
	resetTour: () => set({ activeTour: null }),
}));
