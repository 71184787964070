import { route } from 'react-router-typesafe-routes/dom';

export const DashboardRoutes = {
	DASHBOARD: route(
		'dashboard',
		{},
		{
			BACK_TO_DASHBOARD: route('back-to-dashboard', {}),
		}
	),
};
