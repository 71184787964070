import {
	TAnnouncement,
	TStrapiResponseArray,
} from '@modules/FreeOnboarding/types';
import { ApiRequest } from '@zeal/zeal-lib';

const apiToken = process.env.REACT_APP_STRAPI_TOKEN;
const apiURL = process.env.REACT_APP_STRAPI_URL ?? '';

export const strapiAnnouncementsURL = `${apiURL}/api/free-home-announcements`;

export async function apiAnnouncements(lang: string, pageParam: number) {
	return new ApiRequest(strapiAnnouncementsURL)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.addHeader('Authorization', `Bearer ${apiToken}`)
		.addQuery('locale', lang)
		.addQuery('pagination[pageSize]', '100')
		.addQuery('pagination[page]', pageParam?.toString())
		.addQuery('populate', '*')
		.GET<TStrapiResponseArray<TAnnouncement>>({
			noAuth: true,
		})
		.then((payload) => payload);
}
