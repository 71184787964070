import { ParentShimmer } from '@zeal/web-ui';
import React from 'react';

export default function MagicLinkLoading() {
	return (
		<ParentShimmer isLoading>
			<div
				className="w-full"
				style={{
                    height: '708px',
                    width: '1000px',
				}}
			/>
		</ParentShimmer>
	);
}
