import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '@app/types/types';

const techniciansSlotsPathOptions: TPathOptions = {
	apiKey: 'menu',
	path: ROUTES.TECHNICIANS_SLOTS.path,
	label: 'technicians_slots',
	iconName: 'calendar',
	menuOrder: 10,
	excludedFor: [],
	ownApp: true,
};

export default techniciansSlotsPathOptions;
