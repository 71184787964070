import { CustomStepProps } from '@zeal/web-ui';

export const overviewSteps: (
	t: (key: string) => string
) => CustomStepProps[] = (t) => [
	{
		selector: '#overview-data-filtering',
		title: t('filter_data_title'),
		description: t('filter_data_description'),
		arrowPosition: 'top-center',
		tourPlace: 'bottom',
	},
	{
		selector: '#overview-kpis',
		title: t('kpis_overview_title'),
		description: t('kpis_overview_description'),
		arrowPosition: 'top-center',
		tourPlace: 'bottom',
	},
	{
		selector: '#overview-charts',
		title: t('chart_customization_title'),
		description: t('chart_customization_description'),
		arrowPosition: 'bottom',
		tourPlace: 'top-left',
	},
	{
		selector: '#overview-customer-status',
		title: t('customer_status_title'),
		description: t('customer_status_description'),
		arrowPosition: 'bottom',
		tourPlace: 'top',
	},
	{
		selector: '#overview-feedback',
		title: t('feedback_analytics_title'),
		description: t('feedback_analytics_description'),
		arrowPosition: 'bottom',
		tourPlace: 'top',
		tourOverrideStyles: '!top-[-275px] !left-1/4',
	},
	{
		selector: '#overview-feedback-2',
		title: t('feedback_analytics_title'),
		description: t('feedback_analytics_description'),
		arrowPosition: 'bottom',
		tourPlace: 'top',
	},
];
