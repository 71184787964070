import { Flex, palette } from '@zeal/web-ui';
import styled, { css } from 'styled-components';

export const BannerIconContainer = styled(Flex)(() => [
	css`
		display: flex;
		width: 40px;
		height: 40px;
		padding: 12px;
		flex-direction: column;
		border-radius: 888px;
		background: rgba(247, 237, 208, 0.2);
	`,
]);

export const SectionHorizontalDivider = styled.span`
	height: 1px;
	display: block;
	background: ${palette.divider.default};
	border-radius: 2px;
`;
