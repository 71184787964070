import { Label, palette } from '@zeal/web-ui';
import React from 'react';
import { LetterWrapper } from '../StyledComponents';

const initialsTextColor = {
	0: palette.general.text.one,
	1: palette.general.text.two,
	2: palette.general.text.three,
};

const initialsBackgroundColor = {
	0: palette.general.surface.one,
	1: palette.general.surface.two,
	2: palette.general.surface.three,
};

export default function NavbarInitials({
	username,
}: {
	username: string | null;
}) {
	const letterColour = generateNumberFromUsername(username || '') as 0 | 1 | 2;

	const initials = (username || '?')
		?.split(' ')
		.reduce((s: string[], w) => {
			s.push(w[0]?.toUpperCase() || '');
			return s;
		}, [])
		.splice(0, 2);

	return (
		<LetterWrapper
			align="center"
			justify="center"
			style={{
				backgroundColor: initialsBackgroundColor[letterColour],
				color: initialsTextColor[letterColour],
			}}
		>
			<Label.Thin400 variant="inherit">{initials}</Label.Thin400>
		</LetterWrapper>
	);
}

function generateNumberFromUsername(username: string) {
	let sum = 0;

	for (let i = 0; i < username.length; i++) {
		sum += username.charCodeAt(i);
	}

	return sum % 3;
}
