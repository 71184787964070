import React from 'react';

import NewOrderNotificationWav from '@modules/assets/sounds/new_order.wav';
import NewOrderNotificationMPEG from '@modules/assets/sounds/new_order.mp3';
import NewOrderNotificationOGG from '@modules/assets/sounds/new_order.ogg';
import { ORDER_AUDIO_ID } from './useNotificationSound';

export const NotificationAudioComponent = () => {
	return (
		<audio
			id={ORDER_AUDIO_ID}
			loop
			style={{
				display: 'none',
			}}
		>
			<source src={NewOrderNotificationWav} type="audio/wav" />
			<source src={NewOrderNotificationMPEG} type="audio/mpeg" />
			<source src={NewOrderNotificationOGG} type="audio/ogg" />
			Your browser does not support the audio element.
		</audio>
	);
};
