import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const topupPathOptions: TPathOptions = {
	apiKey: 'topup',
	path: ROUTES.TOP_UP.path,
	label: 'top_up',
	iconName: 'scroll',
	menuOrder: false,
	ownApp: false,
};

export default topupPathOptions;
