import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions, RouteFlags } from '../App/types/types';

const exportsPathOptions: TPathOptions = {
	apiKey: 'export',
	path: ROUTES.EXPORTS.path,
	label: 'exports',
	iconName: 'fileChartColumn',
	menuOrder: 10,
	ownApp: false,
	excludedFor: [RouteFlags.NI_MERCHANT],
	subMenu: [
		{
			path: ROUTES.EXPORTS.VISITS.path,
			label: 'visit_transactions',
			featureApiKey: 'export:visits',
		},
		{
			path: ROUTES.EXPORTS.CUSTOMERS.path,
			label: 'customers',
			featureApiKey: 'export:customers',
		},
		{
			path: ROUTES.EXPORTS.PROMO_CODES.path,
			label: 'promocodes',
			featureApiKey: 'export:promo_code',
		},
		{
			path: ROUTES.EXPORTS.WHEEL_OF_FORTUNE.path,
			label: 'wheeloffortune',
			featureApiKey: 'export:wheel_of_fortune',
		},
	],
};

export default exportsPathOptions;
