import { route } from 'react-router-typesafe-routes/dom';

export const GalleryRoutes = {
	GALLERY: route(
		'gallery',
		{},
		{
			PHOTOS: route('photos', {}),
			VIDEOS: route('videos', {}),
		}
	),
};
