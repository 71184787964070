import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const menuManagementPathOptions: TPathOptions = {
	apiKey: 'menu',
	path: ROUTES.MENU_MANAGEMENT.path,
	label: 'menu',
	iconName: 'folders',
	menuOrder: 1,
	ownApp: true,
};

export default menuManagementPathOptions;
