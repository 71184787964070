import React, { useCallback } from 'react';
import useSidebarSettings, {
	getSidebarSettings,
} from '../data/useSidebarSettings';
import { FeatureKey } from './types';

interface FeatureControlProps {
	featureApiKey: FeatureKey;
}

export const FeatureControl = (
	props: React.PropsWithChildren<FeatureControlProps>
) => {
	const { featureApiKey, children } = props;

	const { sidebarSettings, isLoading: isLoadingSidebarSettings } =
		useSidebarSettings();
	const visibleFeatures = sidebarSettings || {};

	const isAllowedRoutesEmpty = Object.keys(visibleFeatures).length === 0;

	const isFeatureVisible = Boolean(visibleFeatures[featureApiKey]?.visible);

	if (
		isLoadingSidebarSettings ||
		(!isFeatureVisible && !isAllowedRoutesEmpty)
	) {
		return <></>;
	}
	return <>{children}</>;
};

export const useFeatureControl = (key: FeatureKey) => {
	const { sidebarSettings, isLoading: isLoadingSidebarSettings } =
		useSidebarSettings();
	const visibleFeatures = sidebarSettings || {};

	const isAllowedRoutesEmpty = Object.keys(visibleFeatures).length === 0;

	const isFeatureVisible =
		Boolean(visibleFeatures[key]?.visible) || isAllowedRoutesEmpty;

	return {
		isFeatureVisible,
		isLoading: isLoadingSidebarSettings,
	};
};

export const getFeatureControl = (key: FeatureKey) => {
	const visibleFeatures = getSidebarSettings();

	const isAllowedRoutesEmpty = Object.keys(visibleFeatures).length === 0;

	const isFeatureVisible =
		Boolean(visibleFeatures[key]?.visible) || isAllowedRoutesEmpty;

	return {
		isFeatureVisible,
	};
};

export const isFeatureVisible = (key: FeatureKey): boolean => {
	const { isFeatureVisible } = getFeatureControl(key);

	return isFeatureVisible;
};

export const useIsFeatureVisible = () => {
	const { sidebarSettings, isLoading: isLoadingSidebarSettings } =
		useSidebarSettings();
	const visibleFeatures = sidebarSettings || {};

	const isAllowedRoutesEmpty = Object.keys(visibleFeatures).length === 0;

	const isFeatureVisible = useCallback(
		(key: FeatureKey) =>
			Boolean(visibleFeatures[key]?.visible) || isAllowedRoutesEmpty,
		[visibleFeatures, isAllowedRoutesEmpty]
	);

	return {
		isFeatureVisible,
		isLoading: isLoadingSidebarSettings,
	};
};
