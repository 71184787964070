import { list } from 'postcss';

export const ENDPOINTS = {
	dashboard: {
		newFeatureAnnouncement: (apiURL: string, currentLanguage: string) =>
			`${apiURL}/api/web-vendor-feature-announcements?locale=${currentLanguage}`,
		analytics: {
			cards: 'v3/analytics/payment/cards',
			transactions: `v3/analytics/payment/transactions-line-graph`,
			newVsReturning: `v3/analytics/new-vs-returning-range`,
			newsFeed: `v3/newsfeed`,
		},
	},
	analytics: {
		acquisitions: 'v3/analytics/new-vs-returning',
		punches: 'v3/analytics/punch/percentage',
		businessPerformance: 'v3/analytics/visits/business-performance',
		feedback: 'v3/analytics/businesses/feedback-overview',
		lapsedCustomers: 'v3/analytics/churn',
		merchantSales: 'v3/analytics/visits/sales',
		overview: 'v3/analytics/overview',
		peakHours: 'v3/analytics/peak',
		pointActivity: 'v3/analytics/visits/activity',
		redeemed: 'v3/analytics/top-redeemed',
		branchAnalytics: 'v3/analytics/branches/top',
		componentsList: 'v3/analytics/component/list',
	},
	announcements: {
		list: 'v3/actionable-offers',
		create: 'v3/actionable-offers',
		update: (uuid: string) => `v3/actionable-offers/${uuid}` as const,
		delete: (uuid: string) => `v3/actionable-offers/${uuid}` as const,
	},
	promocodes: {
		listSelector: 'promos',
		list: 'v3/promocodes',
		applicableFor: 'v3/enhanced/promocodes/applicables',
		create: 'v3/enhanced/promocodes',
		update: (uuid: string) => `v3/enhanced/promocodes/${uuid}` as const,
		delete: (uuid: string) => `promos/${uuid}` as const,
		toggle: (uuid: string) =>
			`v4/promo-codes/${uuid}/toggle-activation` as const,
		reports: {
			list: 'v4/promo-codes/report',
			export: 'v4/promo-codes/export',
		},
	},
	articles: {
		list: 'articles',
		create: 'articles',
		delete: (id: string) => `articles/${id}/delete`,
		publish: (id: string) => `articles/${id}/publish`,
	},
	freemiumDashboard: {
		summary: 'freemium/home/analytics/summary',
		feedbackAnalytics: 'freemium/home/analytics/feedback',
		businessRating: 'freemium/home/analytics/business-rating',
		visitSegments: 'freemium/home/analytics/visit-segments',
		setTerminalSettings: 'freemium/settings/terminal/store',
		getTerminalSettings: 'freemium/settings/terminal/get',
		cardSalesPerformance: 'freemium/home/analytics/sales-transactions',
		branchPerformance: 'freemium/home/analytics/branch-overview',
		upgradePlan: {
			list: 'freemium/business/subscription/request',
			upgrade: 'freemium/business/subscription/request/store',
		},
	},
	premiumDashboard: {
		loyaltyPerformance: 'freemium/home/analytics/loyalty-program',
	},
	freemiumLoyalty: {
		memberInsights: 'premium/loyalty/reports/summary-cards/member-insights',
		pointsInsights: 'premium/loyalty/reports/summary-cards/points-insights',
		settings: 'v4/loyalty/settings',
		createSettings: 'v4/loyalty/create-or-update',
		updateLoyaltyType: 'premium/update-subscription-type-plan',
		earned: {
			report: 'premium/loyalty/reports/points/earned',
			export: 'premium/loyalty/reports/points/earned/export',
		},
		burned: {
			report: 'premium/loyalty/reports/points/burned',
			export: 'premium/loyalty/reports/points/burned/export',
		},
		redeemed: {
			report: 'premium/loyalty/reports/points/redeemed',
			export: 'premium/loyalty/reports/points/redeemed/export',
		},
		pointsExpired: {
			report: 'premium/loyalty/reports/points/points-expired',
			export: 'premium/loyalty/reports/points/points-expired/export',
		},
		vouchersExpired: {
			report: 'premium/loyalty/reports/points/vouchers-expired',
			export: 'premium/loyalty/reports/points/vouchers-expired/export',
		},
		downloads: {
			list: 'premium/loyalty/downloads',
		},
	},
	consumerReport: {
		list: 'premium/consumers',
	},
	PremiumTransactions: {
		summary: 'premium/transactions/summary-cards',
		list: 'premium/transactions/list',
		export: 'premium/transactions/export',
		downloads: {
			list: 'premium/transactions/downloads',
		},
	},
	freemiumTeams: {
		administrators: {
			list: 'freemium/teams/administrators',
			create: 'freemium/teams/administrators/create-or-update',
			update: 'freemium/teams/administrators/create-or-update',
			show: (uuid: string) => `freemium/teams/administrators/${uuid}`,
			delete: (uuid: string) => `freemium/teams/administrators/${uuid}`,
		},
		teamMembers: {
			list: 'freemium/teams/members',
			create: 'freemium/teams/members/create-or-update',
			update: 'freemium/teams/members/create-or-update',
			show: (uuid: string) => `freemium/teams/members/${uuid}`,
			delete: (uuid: string) => `freemium/teams/members/${uuid}`,
		},
	},
	baristas: {
		list: (query: string) => `v3/barista?${query}` as const,
		createUpdate: `v3/barista`,
		delete: (uuid: string) => `v3/barista/${uuid}` as const,
		areas: {
			create: `v3/barista/tags`,
		},
		services: {
			create: `v3/barista/tags`,
		},
	},
	branches: {
		/*
		 * @deprecated
		 */
		list: 'v3/branch',
		list_v4: 'v4/branches',
		show: (branchId?: string) => `v3/branch/${branchId}` as const,
		create: 'v3/branch/update-or-create',
		update: `v3/branch/update-or-create`,
		updateOrder: `v3/branch/rank/update`,
		delete: (branchId: string) => `branches/${branchId}` as const,
		workingDays: {
			list: (branchId: string) => `v3/branch/${branchId}/hours` as const,
			create: (branchId: string) => `v3/branch/${branchId}/hours` as const,
		},
		deliveryDays: {
			list: (branchId: string) =>
				`v4/branches/delivery-working-hours/${branchId}` as const,
			create: (branchId: string) =>
				`v4/branches/delivery-working-hours/${branchId}` as const,
		},
		photos: {
			list: (branchId: string) => `v3/branch/${branchId}/photos` as const,
			create: (branchId: string) => `v3/branch/${branchId}/photos` as const,
			delete: (branchId: string, photoId: string) =>
				`v3/branch/${branchId}/photos/${photoId}` as const,
		},
		deliveryAreas: {
			list: (branchId: string) =>
				`v4/branches/delivery-areas/${branchId}` as const,
			update: (branchId: string) =>
				`v4/branches/delivery-areas/${branchId}` as const,
			delete: (branchId: string, deliveryAreaId: string) =>
				`v4/branches/delivery-areas/${branchId}/${deliveryAreaId}` as const,
		},
	},
	exports: {
		visits: 'visits/export',
		customers: 'members/exports/index',
		general: 'v4/export',
	},
	feedbacks: {
		list: 'v3/feedbacks',
		overview: 'v3/feedbacks/overview',
	},
	gallery: {
		photos: {
			list: 'v3/gallery',
			create: 'v3/gallery/photo',
			update: (id: string) => `v3/gallery/photo/${id}` as const,
			delete: (id: string) => `v3/gallery/${id}` as const,
		},
		videos: {
			list: 'v3/gallery',
			create: 'v3/gallery/video',
			update: (id: string) => `v3/gallery/video/${id}` as const,
			delete: (id: string) => `v3/gallery/${id}` as const,
		},
	},
	menu: {
		menuCategory: {
			create: 'menus/category',
			update: (uuid: string) => `menus/category/${uuid}` as const,
			delete: (uuid: string) => `menus/category/${uuid}` as const,
		},
		branchMenu: {
			listBranchesMenus: 'menus/branches',
			showMenu: (menuId: string) => `v3/menus/${menuId}` as const,
			create: 'menus',
			clone: 'menus/clone',
			delete: (uuid: string) => `menus/${uuid}` as const,
		},
		menuItem: {
			createOrUpdate: 'v3/menus/categories/items/create-or-update',
			deleteItem: (itemId: string) => `menus/item/${itemId}` as const,
			updateItemOrder: `v3/menus/categories/items/rank/update`,
			updateCategoryOrder: `v3/menus/categories/rank`,
			updateAvailability: (itemId: string) =>
				`v3/menus/categories/items/${itemId}/availability` as const,
			addItemPhoto: 'menus/item/photo',
			updateEvents: (itemId: string) => `menus/item/${itemId}` as const,
			deletePhoto: (photoId: string) => `menus/item/photo/${photoId}` as const,
			listMenuItems: 'v4/menu-item/branch/list',
		},
	},
	giftCards: {
		list: 'v3/gift-cards',
		create: `v3/gift-cards`,
		update: (uuid: string) => `v3/gift-cards/${uuid}` as const,
		delete: (uuid: string) => `v3/gift-cards/${uuid}` as const,
		reports: {
			list: 'v4/member-gift-card',
			export: 'v4/member-gift-card/export',
		},
	},
	giftFriend: {
		gifts: {
			list: 'v3/gifts',
			create: 'v3/gifts',
			update: (giftId: string) => `v3/gifts/${giftId}` as const,
			delete: (giftId: string) => `v3/gifts/${giftId}` as const,
			categories: 'v3/gifts/categories',
		},
		discounts: {
			list: (giftId: string) => `v3/gifts/${giftId}/discount` as const,
			create: (giftId: string) => `v3/gifts/${giftId}/discount` as const,
			update: (giftId: string) => `v3/gifts/${giftId}/discount` as const,
			delete: (giftId: string) => `v3/gifts/${giftId}/discount` as const,
		},
		featureRequest: {
			request: 'v3/gift-feature-requests',
			status: 'v3/gift-feature-requests',
		},
	},
	magicLink: {
		getEmail: (token: string) =>
			`v4/magic_links/registration/${token}/get-email`,
		sendCode: 'v4/magic_links/registration/send-code',
		verifyCode: (token: string) =>
			`v4/magic_links/registration/${token}/confirm-code`,
		register: 'v4/magic_links/registration/register-business',
		updatePassword: `v4/magic_links/password-creation/update-password`,
		verifyEmail: `v4/magic_links/registration/verify-email`,
	},
	campaigns: {
		list: 'v4/campaigns',
		show: (uuid: string) => `v4/campaigns/${uuid}`,
		listGoals: `v4/campaigns/goals`,
		listActions: `v3/campaign/actions`,
		summary: (segmentUuid: string) => `v3/campaign/summary/${segmentUuid}`,
		createOrUpdate: `v4/campaigns`,
		delete: (uuid: string) => `v3/campaign/${uuid}/delete`,
		templates: 'v4/campaigns/templates',
		listCards: 'v3/credit-card',
		createStripeSession: `v4/stripe/session/create`,
		storeStripeCardToken: `v4/stripe/create/token`,
		planSubscribe: (uuid: string) => `v4/campaigns/plan/${uuid}/subscribe`,
		consultation: 'v4/campaigns/consulting',
	},
	receipts: {
		list: 'v3/visits',
	},
	recommendations: {
		list: 'product-recommendations',
		create: 'product-recommendations',
		update: (uuid: string) => `product-recommendations/${uuid}` as const,
		delete: (uuid: string) => `product-recommendations/${uuid}` as const,
	},
	records: {
		customerRecords: `v3/members`,
		members: {
			show: (uuid: string) => `members/${uuid}`,
			givePoints: `v4/members/points/add`,
			addBalance: `v4/members/balance/add`,
			subtractBalance: (member_uuid:string)=> `v4/members/${member_uuid}/balance/remove`,
			export: `members/exports/store`,
		},
		visits: `v3/visits`,
		notifications: `v4/notification/review`,
		vouchers: (uuid: string) => `v3/voucher-wallet/${uuid}`,
		redeems: (uuid: string) => `v4/members/${uuid}/redeems`,
	},
	segments: {
		list: 'v3/segments',
		listV4: 'v4/segments',
		create: 'v3/segments',
		delete: (uuid: string) => `v3/segments/${uuid}` as const,
	},
	stories: {
		list: 'v3/stories',
		listArchived: 'v3/specifics',
		create: `v3/stories`,
		update: (uuid: string) => `v3/stories/${uuid}` as const,
		delete: (uuid: string) => `v3/stories/${uuid}` as const,
	},
	subscriptions: {
		list: `v4/subscriptions`,
		listArchived: `v4/subscriptions`,
		show: (subscriptionId: string) => `v4/subscriptions-v2/${subscriptionId}`,
		create: `v4/subscriptions-v2`,
		updateStatus: (subscriptionId: string) =>
			`v4/subscriptions/${subscriptionId}/update-status`,
		delete: (subscriptionId: string) => `v4/subscriptions-v2/${subscriptionId}`,
		categories: {
			list: 'v4/subscriptions/categories',
			listArchived: 'v4/subscriptions/categories',
		},
	},
	topUp: {
		paymentKey: 'v3/credit-card/create-token',
		listPlans: 'v3/campaign/plans',
		listCards: 'v3/credit-card',
		setDefaultCard: (id: string) => `v3/credit-card/${id}/mark-as-default`,
		deleteCard: (id: string) => `v3/credit-card/${id}/delete`,
		storeCard: 'v3/credit-card',
		selectPlan: `v3/campaign/plans`,
	},
	tour: {
		listSettings: 'freemium/onboarding-settings',
		updateKeys: 'freemium/onboarding-settings/update',
	},
	techniciansSlots: {
		listDurations: (technicianId: string) =>
			`v4/teams/${technicianId}/working-hours` as const,
		createDurations: (technicianId: string) =>
			`v4/teams/${technicianId}/working-hours` as const,
		deleteDurations: (technicianId: string, durationId: string) =>
			`v4/teams/${technicianId}/working-hours/${durationId}` as const,
	},
	teamsMembers: {
		admins: {
			list: `v3/business-admins`,
			show: (uuid: string) => `v3/business-admins/${uuid}`,
			add: `v3/business-admins`,
			edit: (uuid: string) => `v3/business-admins/${uuid}`,
			delete: (uuid: string) => `v3/business-admins/${uuid}`,
		},
		teamApp: {
			list: `v3/teams`,
			add: `v3/teams`,
			edit: (uuid: string) => `v3/teams/${uuid}`,
			delete: (uuid: string) => `v3/teams/${uuid}`,
		},
		branches: {
			branch: `v3/branch`,
			technician: (uuid: string) => `v3/branch/${uuid}/technicians`,
		},
		roles: {
			list: `v3/roles`,
			add: `v3/roles`,
			edit: (uuid: string) => `v3/roles/${uuid}`,
			delete: (uuid: string) => `v3/roles/${uuid}`,
		},
	},
	permissions: {
		list: 'permissions',
	},
	VisitTransactions: {
		list: 'v3/visits',
		visitSummaryAnalytics: 'v3/visits/summary',
	},
	wheelOfFortune: {
		list: 'v3/wheels/',
		reports: {
			list: 'v4/wheels/report',
			export: 'v4/wheels/report/export',
		},
		listTrashed: 'v3/wheels/',
		show: (uuid: string) => `v3/wheels/${uuid}`,
		createOrUpdate: 'v3/wheels/create-or-update',
		toggleActive: (uuid: string) => `v3/wheels/${uuid}/toggle-activity`,
		delete: (uuid: string) => `v3/wheels/${uuid}`,
		return: (uuid: string) => `v3/wheels/${uuid}/toggle-trashed`,
		redemptions: {
			list: (uuid: string) => `v4/wheels/${uuid}/attempts`,
		},
	},
	business: {
		info: 'v3/me',
	},
	selectors: {
		areas: 'v3/selector/tags',
		services: 'v3/selector/tags',
		campaignSegments: `v3/selector/segments`,
		campaignChannels: `v3/selector/campaignChannels`,
		branches: `v3/selector/branches`,
		menuItems: `v3/selector/commonMenuItemsForBranches`,
		campaignTemplates: `v3/selector/CampaignMessageTemplates`,
		menuItemBranchVariations: `v3/selector/menuItemBranchVariations`,
		campaignActionsOptions: (selector: string) => `v4/selector/${selector}`,
		languageOptions: `v4/selector/languages`,
		promocodes: 'v4/selector/promoCodes',
	},
	settings: {
		refundPolicies: {
			show: 'v4/business/refund-settings',
			update: 'v4/business/refund-settings',
		},
		tapToPay: {
			toggle: 'v4/settings/payment/toggle-tap-to-pay',
		},
		account: `v3/settings/account`,
		billing: `v4/settings/billing`,
		booking: {
			show: `v4/settings/booking`,
			update: `v4/settings/booking`,
		},
		categories: {
			list: `v3/categories`,
			add: `v3/settings/category`,
		},
		paymentMethod: {
			show: `v4/settings/payment`,
			update: `v4/settings/payment`,
		},
		pos: `v3/pos`,
		profile: {
			show: `v3/business/settings`,
			add: `v3/settings/profile`,
			updatePhoto: `v3/settings/profile/photo`,
			updateCover: `v3/settings/profile/cover`,
		},
		socialMedia: `v3/settings/social-media`,
		stripe: {
			connect: `v4/stripe/express-connect`,
			getStatus: `v4/stripe/express-connect/status`,
		},
		posLanguage: {
			show: `v4/terminal-setting/language`,
			update: `v4/terminal-setting/language/store`,
		},
	},
	orders: {
		list: 'v3/orders',
		show: (orderId: string) => `v3/orders/${orderId}` as const,
		updateActions: (orderId: string) => `v3/orders/${orderId}` as const,
		updateItems: (orderId: string) => `v3/orders/${orderId}/items` as const,
		branchMenu: (branchId: string) => `menus/branch/${branchId}` as const,
		blockedHours: {
			list: 'v4/teams/blocked-hours',
		},
		branches: 'v3/branch',
		technicians: (branchId: string) => `v3/branch/${branchId}/technicians`,
	},
	cardlink: {
		webhooks: {
			fetch: 'cardlink/settings/webhook/get',
			update: 'cardlink/settings/webhook/store',
			toggleTransactionUpdates: 'cardlink/settings/webhook/toggle/transaction',
		},
		message: {
			fetch: 'cardlink/settings/message/get',
			update: 'cardlink/settings/message/store',
		},
		terminal: {
			fetchSettings: 'cardlink/settings/terminal/get',
			fetchContent: 'cardlink/settings/terminal/get/screens',
			storeAppearance: 'cardlink/settings/terminal/store',
			storeContent: 'cardlink/settings/terminal/store/screens',
		},
		branchReferencing: {
			store: 'v4/branches/provider-branch-id',
		},
		setupStatus: 'cardlink/setup-states',
		fetchTermsAndConditions: `cardlink/settings/terms_and_conditions`,
	},
	cardlink_activity: {
		all: 'cardlink/activities',
	},
	calendar: {
		blockedTime: {
			list: 'v4/calendar/blocked-time/',
			create: 'v4/calendar/blocked-time/',
			update: (uuid: string) => `v4/calendar/blocked-time/${uuid}`,
			delete: (uuid: string) => `v4/calendar/blocked-time/${uuid}`,
		},
		technicians: {
			availableSlots: (uuid: string) => `v4/teams/${uuid}/booking-slots`,
		},
	},
	ordersStatusHistory: {
		list: 'v4/business-admin/activity-log/order-status',
	},

	activityLog: {
		list: 'v4/business-admin/activity-log',
	},

	terminalManagement: {
		list: 'v4/payment-terminals',
		stats: 'v4/branches/terminal-metrics',
		showTerminal: (terminalId: string) => `v4/payment-terminals/${terminalId}`,
		reAssignBranch: (terminalId: string, branchId: string) =>
			`v4/payment-terminals/${terminalId}/branch/${branchId}`,
		toggleFeedbackFeature: {
			on: 'v4/payment-terminals/feedback-on',
			off: 'v4/payment-terminals/feedback-off',
		},
		activate: `v4/payment-terminals/activate`,
		deactivate: `v4/payment-terminals/deactivate`,
		tagsSelector: `v4/selector/businessTags?group=1`,
		createTag: `v4/payment-terminals/tags`,
		updateTerminalTags: (terminalId: string) =>
			`v4/payment-terminals/${terminalId}/tags`,
	},
	rewards: {
		model: {
			show: 'punch-properties/model',
			set: 'model',
		},
		model3: {
			showStampCard: (uuid: string) => `v4/rewards/scoped/${uuid}`,
			listStampCards: `v4/rewards/scoped/list`,
			createOrUpdateStampCard: `v4/rewards/scoped/storeOrUpdate`,
			deleteStampCard: (uuid: string) => `v4/rewards/scoped/${uuid}`,
			punchProperties: {
				show: 'v3/punch-properties',
				update: 'v3/punch-properties',
			},
		},
		pointsAndRewards: {
			list: 'v4/rewards/points/list',
			show: (uuid: string) => `v4/rewards/points/${uuid}`,
			createOrUpdate: 'v4/rewards/points/update-or-create',
			delete: (uuid: string) => `v4/rewards/points/${uuid}`,
		},
	},
	files: {
		upload: 'v4/upload/file',
	},
	freeOnboarding: {
		cards: `freemium/home/cards`,
		gettingStartedSettings: `freemium/home/getting-started-settings`,
	},
} as const;
