import React from "react";
import { useTranslation } from "react-i18next";
import { Label, Icon, Card, Flex, palette } from "@zeal/web-ui";
export default function UnAuthOwnApp() {
  const { t } = useTranslation("common");
  return /* @__PURE__ */ React.createElement(Card, { fillHeight: true, m: "md", p: "sm" }, /* @__PURE__ */ React.createElement(
    Flex,
    {
      isColumn: true,
      justify: "center",
      align: "center",
      gap: "sm",
      className: "h-full"
    },
    /* @__PURE__ */ React.createElement(
      Icon,
      {
        color: palette.icon.default,
        iconName: "lockKeyhole",
        variant: "light",
        size: "9x"
      }
    ),
    /* @__PURE__ */ React.createElement(Label.Mid500, null, t("access_restricted")),
    /* @__PURE__ */ React.createElement(Label.Thin300, { variant: "gray" }, t("turn_own_app"))
  ));
}
