import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const SubscriptionsPathEntry: IModuleOptions = {
  ...pathOpt,
  routes: [
    {
      path: ROUTES.SUBSCRIPTIONS.path,
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <Navigate to={ROUTES.SUBSCRIPTIONS.LIST.path} replace />,
        },
        {
          path: ROUTES.SUBSCRIPTIONS.$.LIST.relativePath,
          element: lazy(() => import('./Subscriptions')),
          aclProps: {
            aclGrants: ['view:subscription'],
          },
        },
        {
          path: ROUTES.SUBSCRIPTIONS.$.CATEGORIES.relativePath,
          element: lazy(() => import('./Categories')),
          aclProps: {
            aclGrants: ['view:subscription'],
          },
        },
        {
          path: ROUTES.SUBSCRIPTIONS.$.ADD_SUBSCRIPTION.relativePath,
          element: lazy(() => import('./AddSubscription')),
          aclProps: {
            aclGrants: ['view:subscription'],
          },
        },
        {
          path: ROUTES.SUBSCRIPTIONS.$.VIEW.relativePath,
          element: lazy(() => import('./ViewSubscription')),
          aclProps: {
            aclGrants: ['view:subscription'],
          },
        },
      ],
    },
  ],
};