import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions, RouteFlags } from '../App/types/types';

const teamsPathOptions: TPathOptions = {
	apiKey: 'teams',
	exact: false,
	menuOrder: 3,
	ownApp: false,
	path: ROUTES.TEAMS.path,
	label: 'teams',
	iconName: 'userGroup',
	subMenu: [
		{
			label: 'administrators',
			featureApiKey: 'teams:team_app',
			path: ROUTES.TEAMS.DASHBOARD.path,
		},
		{
			label: 'teams_app',
			path: ROUTES.TEAMS.TEAM_APP.path,
			featureApiKey: 'teams:team_app',
			excludedFor: [RouteFlags.NI_MERCHANT],
		},
		{
			label: 'manage_roles',
			featureApiKey: 'teams:manage_roles',
			path: ROUTES.TEAMS.ROLES.path,
		}
		
	],
};

export default teamsPathOptions;
