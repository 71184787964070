import { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';

export const ArticlesPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.ARTICLES.path,
			element: lazy(() => import('./Articles')),
			aclProps: {
				aclGrants: ['articles:read'],
			},
		},
	],
};

export default ArticlesPathEntry;
