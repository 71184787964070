export enum EMagicLinkLSKeys {
	token = 'MAGIC_LINK_TOKEN',
	code = 'MAGIC_LINK_CODE',
	email = 'MAGIC_LINK_EMAIL',
	type = 'MAGIC_LINK_TYPE',
	step = 'MAGIC_LINK_STEP',
}

function getStoredKey(key: EMagicLinkLSKeys) {
	return localStorage.getItem(key);
}

function setStoredKey(key: EMagicLinkLSKeys, value: string) {
	localStorage.setItem(key, value);
}

function removeStoredKey(key: EMagicLinkLSKeys) {
	localStorage.removeItem(key);
}

function clearStoredKeys() {
	Object.values(EMagicLinkLSKeys).forEach((key) => {
		localStorage.removeItem(key);
	});
}

export const LSFormHandler = {
	getStoredKey,
	setStoredKey,
	removeStoredKey,
	clearStoredKeys,
};
