import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const tipsPathOptions: TPathOptions = {
	apiKey: 'teams:tip_distribution',
	path: ROUTES.TIPS.path,
	label: 'tips',
	iconName: 'userGroup',
	menuOrder: 8,
	ownApp: false,
	subMenu: [
		{
			label: 'history',
			path: ROUTES.TIPS.HISTORY.path,
		},
		{
			label: 'tip_distribution',
			path: ROUTES.TIPS.DISTRIBUTION.path,
		},
	],
};

export default tipsPathOptions;
