import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const giftCardPathOptions: TPathOptions = {
	apiKey: 'gift_card',
	path: ROUTES.GIFT_CARD.path,
	label: 'gift_card',
	iconName: 'giftCard',
	menuOrder: 6,
	ownApp: true,
	excludedFor: [],
	subMenu: [
		{
			path: ROUTES.GIFT_CARD.LIST.path,
			label: 'list',
		},
		{
			path: ROUTES.GIFT_CARD.REPORTS.path,
			label: 'reports',
		},
	],
};

export default giftCardPathOptions;
