import React from 'react';
import { PathRouteProps, Route } from 'react-router-dom';

import { IModuleOptions } from '@app/types/types';
import ACLBoundary, { ACLBoundaryProps } from '@app/ACL/ACLBoundary';
import { isFeatureVisible } from '@app/FeatureControl/FeatureControl';

export function renderRoute({
	path,
	element: element,
	children,
	aclProps,
	featureApiKey,
	...rest
}: IModuleOptions['routes'][0]) {
	const Element = element as React.JSXElementConstructor<any>;

	if (featureApiKey && !isFeatureVisible(featureApiKey)) {
		return null;
	}

	return (
		<Route
			key={path}
			{...(rest as PathRouteProps)}
			path={path}
			element={
				<ACLBoundary
					{...(aclProps as ACLBoundaryProps)}
					aclUnAuthProps={{
						...aclProps?.aclUnAuthProps,
						replace: true,
					}}
				>
					{
						// check if it is a JSX element or a Component
						React.isValidElement(element) ? (
							element
						) : typeof element === 'object' ? (
							<Element />
						) : null
					}
				</ACLBoundary>
			}
			children={children ? children?.map(renderRoute) : undefined}
		/>
	);
}
