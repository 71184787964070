import { ROUTES } from '@app/AppRoutes/routes';
import { Button } from '@zeal/web-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function UpgradeButton() {
	const { t } = useTranslation('common');
	const navigate = useNavigate();

	return (
		<Button
			size="md"
			variant="primary"
			onClick={() => navigate(ROUTES.FREEMIUM_DASHBOARD.UPGRADE_PLAN.path)}
		>
			{t('upgrade')}
		</Button>
	);
}
