import { CustomStepProps } from '@zeal/web-ui';

export const posSteps: (t: (key: string) => string) => CustomStepProps[] = (
	t
) => [
	{
		selector: '#upload-logo',
		title: t('upload_logo_title'),
		description: t('upload_logo_description'),
		arrowPosition: 'left',
		tourPlace: 'right',
		tourOverrideStyles: '!top-[-75px] !left-[15px]',
	},
	{
		selector: '#terminal-color',
		title: t('select_terminal_color_title'),
		description: t('select_terminal_color_description'),
		arrowPosition: 'left',
		tourPlace: 'right',
		tourOverrideStyles: '!top-[50px] !left-[15px]',
	},
	{
		selector: '#background-and-text',
		title: t('select_bg_and_text_title'),
		description: t('select_bg_and_text_description'),
		arrowPosition: 'left',
		tourPlace: 'extra-left',
	},
	{
		selector: '#preview-pos',
		title: t('success_preview_pos_title'),
		description: t('success_preview_pos_description'),
		arrowPosition: 'right',
		tourPlace: 'right',
		tourOverrideStyles: '!top-[50px] !left-[-15px]',
	},
];
