import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Outlet } from 'react-router-dom';

export const RewardsPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.REWARDS.path,
			element: <Outlet />,
			aclProps: {
				aclGrants: ['view:loyalty'],
			},
			children: [
				{
					index: true,
					element: lazy(() => import('./Rewards')),
				},
				{
					path: ROUTES.REWARDS.$.POINTS_AND_REWARDS.relativePath,
					element: lazy(
						() => import('./pages/PointsAndRewards/PointsAndRewards')
					),
				},
			],
		},
	],
};
