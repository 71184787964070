import { ApiRequest } from '@zeal/zeal-lib';

import { ENDPOINTS } from '../endpoints';

export async function apiPermissions() {
	return new ApiRequest(ENDPOINTS.permissions.list)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-type', 'application/json')
		.GET();
}
