import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const consumerReportPathOptions: TPathOptions = {
	apiKey: 'consumer_report',
	exact: false,
	menuOrder: 3,
	ownApp: false,
	path: ROUTES.CONSUMER_REPORTS.path,
	label: 'consumer_report',
	excludedFor: [],
	iconName: 'peopleGroup',
};

export default consumerReportPathOptions;
