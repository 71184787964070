import { usePermissions } from "@app/data/TeamMembers";
import { useLoggedIn } from "./useLogin";
export default function useGrants() {
  const { permissions: userPermissions, isSuperAdmin } = useLoggedIn();
  const { formattedPermissions, isLoading: isFetchingPermissions } = usePermissions();
  return {
    userGrants: userPermissions,
    isSuperAdmin,
    allFormattedGrants: formattedPermissions,
    isFetchingPermissions
  };
}
