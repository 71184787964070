import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const freemiumDashboardPathOptions: TPathOptions = {
	apiKey: 'freemium_overview',
	path: ROUTES.FREEMIUM_DASHBOARD.path,
	label: 'overview',
	iconName: 'chartPie',
	menuOrder: 2,
	excludedFor: [],
	ownApp: false,
};

export default freemiumDashboardPathOptions;
