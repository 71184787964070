import { ApiRequest } from '@zeal/zeal-lib';
import { ENDPOINTS } from '../endpoints';

export async function requestGiftFriendFeature({ ...payload }) {
	return new ApiRequest(ENDPOINTS.giftFriend.featureRequest.request)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(payload));
}
export async function giftFriendRequestStatus({ queryKey: [, filters] }: any) {
	return new ApiRequest(ENDPOINTS.giftFriend.featureRequest.request)
		.addHeader('Accept', 'application/json')
		.GET<
			ApiResponse<{
				is_enabled: boolean;
				gift_feature_request: null;
			}>
		>();
}
