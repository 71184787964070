import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const campaignsPathOptions: TPathOptions = {
	apiKey: 'campaigns',
	path: ROUTES.CAMPAIGNS.path,
	label: 'campaigns',
	iconName: 'bullhorn',
	menuOrder: 3,
	ownApp: true,
	excludedFor: [],
	subMenu: [
		{
			featureApiKey: 'campaigns',
			label: 'campaigns',
			path: ROUTES.CAMPAIGNS.LIST.path,
		},
		{
			label: 'segments',
			path: ROUTES.CAMPAIGNS.SEGMENTS.path,
		},
	],
};

export default campaignsPathOptions;
