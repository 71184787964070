import React, { memo, useMemo } from 'react';

import { AppHeader, PageTitleContainer } from './components/StyledComponents';
import { Flex } from '@zeal/web-ui';

import NavBarAvatar from './components/NavBarAvatar';
import OwnAppSwitcher from './components/OwnAppSwitcher';
import DashboardLogo from './components/DashboardLogo';
import { useLoginVault } from '@app/store/authStore';
import UpgradeButton from './components/UpgradeButton';

type NavBarProps = {
	pageTitle?: React.ReactNode;
	tabs?: any;
};

function NavBar({ pageTitle, tabs }: NavBarProps) {
	const { merchantType } = useLoginVault();

	const appHeaderEndContent = useMemo(
		() => (
			<Flex gap="sm" justify="end" align="center" className="overflow-hidden">
				{merchantType === 'freemium' && <UpgradeButton />}
				<NavBarAvatar />
				<OwnAppSwitcher />
				{/* <Notifications /> */}
			</Flex>
		),
		[]
	);

	return (
		<AppHeader tabs={tabs}>
			<DashboardLogo />
			<PageTitleContainer>
				<span />
				{pageTitle || <span />}
			</PageTitleContainer>
			{appHeaderEndContent}
		</AppHeader>
	);
}

export default memo(NavBar);
