import React, { createContext, useContext, useMemo, useState } from "react";
import { useAppState } from "../store/appStore";
export function useOwnAppAccess() {
  const { isCustomAppActive } = useAppState();
  return {
    isRouteAllowed: (isOwnAppContainer) => isOwnAppContainer && isCustomAppActive || !isOwnAppContainer
  };
}
const OwnAppContext = createContext({
  isOwnApp: true,
  setIsOwnApp: () => {
  }
});
export function OwnAppProvider({ children }) {
  const [isOwnApp, setIsOwnApp] = useState(false);
  const ctx = useMemo(() => ({ isOwnApp, setIsOwnApp }), [isOwnApp]);
  return /* @__PURE__ */ React.createElement(OwnAppContext.Provider, { value: ctx }, children);
}
export function useOwnAppProvider(init) {
  const { isOwnApp, setIsOwnApp } = useContext(OwnAppContext);
  if (typeof init === "boolean") {
    setIsOwnApp(init);
  }
  return [isOwnApp, setIsOwnApp];
}
