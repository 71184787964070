import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import * as Yup from 'yup';

export const CampaignsRoutes = {
	CAMPAIGNS: route(
		'campaigns',
		{},
		{
			LIST: route(
				'list',
				{},
				{
					DETAILS: route(':uuid', {
						params: {
							uuid: yup(Yup.string().uuid()),
						},
					}),
					START_CAMPAIGN: route('startCampaign', {}),
					EDIT_CAMPAIGN: route('editCampaign/:uuid', {
						params: {
							uuid: yup(Yup.string().uuid()),
						},
					}),
					PLANS: route('plans', {}),
				}
			),
			PAYMENT: route(
				'payment',
				{},
				{
					PLAN: route(':uuid', {
						params: {
							uuid: yup(Yup.string().uuid()),
						},
					}),
				}
			),
			SEGMENTS: route('segments', {}),
		}
	),
};
