import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const CardLinkDashboardPathEntry: IModuleOptions = {
  ...pathOpt,
  routes: [
    {
      path: ROUTES.CARDLINK_DASHBOARD.path,
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <Navigate to={ROUTES.CARDLINK_DASHBOARD.DASHBOARD.path} replace />,
        },
        {
          path: ROUTES.CARDLINK_DASHBOARD.$.DASHBOARD.relativePath,
          element: lazy(() => import('./CardLinkDashboard')),
          children: [
            {
              index: true,
              element: lazy(() => import('./CardLinkDashboard')),
            },
            {
              path: ROUTES.CARDLINK_DASHBOARD.DASHBOARD.$.BRANCH.relativePath,
              element: lazy(() => import('./CardLinkDashboard')),
            },
          ],
        },
      ],
    },
  ],
};
