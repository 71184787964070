import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    const element = document.getElementById("app-container-content");

    if (element) {
      element.scrollTop = 0;
    }
  }, [location]);
  
  return null;
};

export default ScrollToTop;
