import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export const BRGRAppId = '3';

export type AppState = {
	hasCustomApp: boolean;
	isCustomAppActive: boolean;
	customAppName: string | null;
	customAppId: number | string | null;
	currentAppId: number | string | null;
	currentAppName: string | null;
	isSwitchedOnLoad: boolean;
};

interface AppStore {
	appState?: AppState;
	updateAppState: (appState: Partial<AppState>) => void;
	activateCustomApp: ({
		appName,
		appId,
	}: {
		appName: AppState['customAppName'];
		appId: AppState['customAppId'];
	}) => void;
	clearData: () => void;
}

const initialState = {
	appState: {
		hasCustomApp: false,
		isCustomAppActive: false,
		customAppName: null,
		customAppId: null,
		currentAppId: null,
		currentAppName: null,
		isSwitchedOnLoad: false,
	},
};

export const useAppStore = create<AppStore>()(
	devtools(
		persist(
			(set, _get) => ({
				...initialState,
				updateAppState: (appState) => {
					const currentAppState = _get().appState || ({} as AppState);
					set({
						appState: {
							...currentAppState,
							...appState,
						},
					});
				},
				activateCustomApp: ({ appName, appId }) => {
					const currentAppState = _get().appState || ({} as AppState);
					set({
						appState: {
							...currentAppState,
							isCustomAppActive: true,
							currentAppId: appId,
							currentAppName: appName,
							isSwitchedOnLoad: true,
						},
					});
				},
				clearData: () => {
					set(initialState);
				},
			}),
			{ name: 'app-store' }
		)
	)
);

export function useAppState() {
	const appState = useAppStore((state) => state.appState);
	return appState || ({} as AppState);
}
