import { createRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getFireBaseToken, onMessageListener } from '../../firebase';

import openToaster from '../Toastr/openToastr';
import { queryClient } from '../QueryClient';
import { generatedKeys } from '../data';
import { MessagePayload } from 'firebase/messaging';
import useSaveToken from '@app/data/useFirebaseNotifications';
import { useLoggedIn } from '@app/ACL/useLogin';
import { useNotificationSound } from './useNotificationSound';

const lastNotificationRef = createRef<string>();

export const useOrderNotification = () => {
	const { isLoggedIn } = useLoggedIn();
	const { play, stop } = useNotificationSound();

	const navigate = useNavigate();
	const refetchOrders = () => {
		queryClient.invalidateQueries({
			queryKey: generatedKeys?.orders?.base,
		});
	};

	// Refactor this to be a custom hook
	const showNotification = (payload: MessagePayload | null) => {
		play();

		let messageBody: {
			order_uuid?: string;
		} = {};

		try {
			messageBody = JSON.parse(payload?.notification?.body || '{}');
		} catch (error) {
			// FIXME: this needs to be fixed
			// @ts-expect-error
			messageBody = payload?.notification?.body || '';
		}
		const orderId = messageBody?.order_uuid;

		if (lastNotificationRef.current == orderId) {
			return;
		}

		refetchOrders();

		const url = orderId ? `/orders/list/${orderId}` : '/orders';

		openToaster({
			message: payload?.notification?.title || '',
			variant: 'success',
			duration: 1000 * 60 * 30, // 30 minutes
			onClick: () => {
				stop();
				navigate(url);
			},
		});

		// FIXME: this needs to be fixed
		// @ts-expect-error
		lastNotificationRef.current = orderId;
	};

	// Refactor this to be a custom hook
	const channel = new BroadcastChannel('notification_channel');
	channel.onmessage = (event) => {
		showNotification(event.data.key);
	};

	onMessageListener()
		.then((payload) => {
			showNotification(payload);
		})
		.catch((err) => console.log('failed: ', err));

	const { mutate: saveToken } = useSaveToken();

	// TODO: Refactor this to be after login
	useEffect(() => {
		if (isLoggedIn) {
			getFireBaseToken().then((token) => {
				if (!!token) {
					saveToken(token);
				}
			});
		}
	}, [isLoggedIn]);
};
