import { route } from 'react-router-typesafe-routes/dom';

export const GiftCardRoutes = {
	GIFT_CARD: route(
		'giftcard',
		{},
		{
			LIST: route('list', {}),
			REPORTS: route('reports', {}),
		}
	),
};
