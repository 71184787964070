import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import * as Yup from 'yup';

export const WheelOfFortuneRoutes = {
	WHEEL_OF_FORTUNE: route(
		'wheelOfFortune',
		{},
		{
			ARCHIVE: route(
				'archive',
				{},
				{
					REDEMPTION_DETAILS: route('redemptionDetails/:id', {
						params: { id: yup(Yup.string().uuid()) },
					}),
				}
			),
			WHEELS: route(
				'wheels',
				{},
				{
					REDEMPTION_DETAILS: route('redemptionDetails/:id', {
						params: { id: yup(Yup.string().uuid()) },
					}),
				}
			),
			REPORT: route('report', {}),
		}
	),
};
