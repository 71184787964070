import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import * as Yup from 'yup';

export const MagicLinkRoutes = {
	MAGIC_LINK: route(
		'free-tier',
		{},
		{
			SIGN_UP: route(
				'sign-up',
				{},
				{
					TOKEN: route(':token', {
						params: {
							token: yup(Yup.string().uuid()),
						},
					}),
				}
			),
			LOGIN: route('login', {}),
			NOT_FOUND: route('*', {}),
		}
	),
};
