import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import * as Yup from 'yup';

export const StoriesRoutes = {
	STORIES: route(
		'stories',
		{},
		{
			PAGE: route('page', {}),
			REQUESTS: route('requests', {}),
			ARCHIVED: route('archived', {}),
		}
	),
};
