import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import {
	useBusinessProperties,
	type BusinessProperties,
} from '../../BusinessAccount/useBusinessInfo';

export function FilteredRoute(props: RouteProps) {
	const { data: businessProperties } = useBusinessProperties();

	const routePath = props.path;

	if (filterPaths(businessProperties)({ to: routePath })) {
		return <Route {...props} />;
	}

	return null;
}

export function filterPaths(businessProperties?: BusinessProperties) {
	return (item: { to: RouteProps['path'] }) => {
		if (item?.to?.includes('/menu-management')) {
			return businessProperties?.ordering || businessProperties?.booking;
		}

		if (item?.to?.includes('/orders')) {
			return businessProperties?.ordering || businessProperties?.booking;
		}
		if (item?.to?.includes('/technicians-slots')) {
			return businessProperties?.booking;
		}

		if (item?.to?.includes('/branches-availability')) {
			return businessProperties?.order_acceptance_status_by == 'branch';
		}

		return true;
	};
}
