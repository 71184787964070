import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const FreemiumTeamsPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.FREEMIUM_TEAMS.path,
			element: <Outlet />,
			children: [
				{
					index: true,
					element: <Navigate to={ROUTES.FREEMIUM_TEAMS.ADMINISTRATORS.path} />,
				},
				{
					path: ROUTES.FREEMIUM_TEAMS.$.ADMINISTRATORS.relativePath,
					element: lazy(() => import('./components/TeamsPage')),
				},
				{
					path: ROUTES.FREEMIUM_TEAMS.$.TEAMS.relativePath,
					element: lazy(() => import('./components/TeamsPage')),
				},
			],
		},
	],
};
